import React, { useEffect } from 'react';
import styles from './CompanyInfoAvatar.module.scss';
import { AppAvatar } from '../../../components/avatar';
import { useLazyQuery } from '@apollo/client';
import { GET_USER } from '../../../api';
import { getAvatarPathBySize } from '../../../utils/appUtils';

const MANAGED_BY = 'Managed by';

const CompanyInfoAvatar = ({ companyInfo, user, size = 'lg' }) => {
  const [getUser, { data: userData }] = useLazyQuery(GET_USER, {
    variables: { id: companyInfo.user_id },
  });

  useEffect(() => {
    if (companyInfo.user_id) {
      (async () => {
        await getUser({
          variables: { id: companyInfo.user_id },
        });
      })();
    }
  }, [companyInfo]);

  const showUser = (userId) => {
    window.location.href = `${process.env.REACT_APP_TT}/dashboard/profile/${userId}`;
  };

  return (
    <>
      <div className={styles.middleContent}>
        {companyInfo?.is_open_for_investment && (
          <div className={styles.status}>Open for investments (OFI)</div>
        )}
        <div className={styles.name}>
          <div className={styles.companyName}>{companyInfo?.company_name}</div>
          {companyInfo?.kyb_completed && <div className={styles.kybFlag}>KYB</div>}
        </div>
        <AppAvatar
          nav={1}
          size={size}
          userName={companyInfo?.company_name}
          src={getAvatarPathBySize(companyInfo?.logos, 'mediumPath')}
        />
        <div className={styles.managedBy}>
          {user && (
            <>
              <span style={{ textWrap: 'nowrap' }}>{`${MANAGED_BY} `}</span>
              <span onClick={() => showUser(userData?.user?.id)} className={styles.managedName}>
                {`${userData?.user?.first_name || ''} ${userData?.user?.last_name || ''}`}
              </span>
            </>
          )}
        </div>
      </div>
    </>
  );
};
export default CompanyInfoAvatar;
