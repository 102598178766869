import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { Layout } from '../../components/layout';
import RegistrationPage from './RegistrationPage';
import LoginPage from './LoginPage';
import ReturnPage from './ReturnPage';

const PAYMENT_ROUTES = [
  {
    path: '/registerAccount',
    element: <RegistrationPage />,
  },
  {
    path: '/loginAccount',
    element: <LoginPage />,
  },
  {
    path: '/return',
    element: <ReturnPage />,
  },
];

const PaymentRoutes = () => {
  return (
    <Routes>
      {PAYMENT_ROUTES.map((routeInfo) => (
        <Route path={routeInfo.path} element={routeInfo.element} key={routeInfo.path} />
      ))}
    </Routes>
  );
};

export default PaymentRoutes;
