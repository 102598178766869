import React, { useEffect, useState } from 'react';
import styles from './CheckoutContactForm.module.scss';
import { Box, Typography } from '@mui/material';
import { Button, Hidden } from 'tt-ui-kit';
import TextField from '@mui/material/TextField/TextField';
import Image from '../../../../Image';
import { useLazyQuery, useMutation } from '@apollo/client';
import {
  CREATE_CONTACT_INFO,
  SHOW_CURRENT_USER_CONTACT_INFOS,
  UPDATE_CONTACT_INFO,
} from '../../../../../api';
import { useWindowSize } from '../../../../../utils/useWindowSize';
import { FormProvider, useForm, useFormContext } from 'react-hook-form';
import AddContactsModal from '../../../CartPage/Checkout/AddContactsModal';
import ContactItemModal from '../../../CartPage/Checkout/AddContactsModal/ContactItemModal';
import AddDeliveriesModal from '../../../CartPage/Checkout/AddDeliveriesModal';
import DeliveryItemModal from '../../../CartPage/Checkout/AddDeliveriesModal/DeliveryItemModal';

const CheckoutContactForm = ({ selectedContact, setSelectedContact }) => {
  const windowSize = useWindowSize();
  const {
    register,
    formState: { errors },
  } = useFormContext();
  const [showCurrentUserContactInfos] = useLazyQuery(SHOW_CURRENT_USER_CONTACT_INFOS);
  const [updateContactInfo] = useMutation(UPDATE_CONTACT_INFO);
  const [createContactInfo] = useMutation(CREATE_CONTACT_INFO);
  const [userContacts, setUserContacts] = useState([]);
  const [addContactsModal, setAddContactsModal] = useState(false);
  const [contactItemModal, setContactItemModal] = useState(null);
  const [addDeliveryModal, setAddDeliveryModal] = useState(false);
  const [deliveryItemModal, setDeliveryItemModal] = useState(null);
  const [selectedDelivery, setSelectedDelivery] = useState({});
  const [deliveries, setDeliveries] = useState([]);
  const contactMethods = useForm();
  const contactItemMethods = useForm();
  const deliveryMethods = useForm();
  const deliveryItemMethods = useForm();

  useEffect(() => {
    (async () => {
      await showUserContactInfos();
    })();
  }, []);

  const showUserContactInfos = async () => {
    const response = await showCurrentUserContactInfos();
    if (response && response.data && response.data.showCurrentUserContactInfos) {
      setUserContacts(response.data.showCurrentUserContactInfos);
    }
  };

  const editUserContactInfo = async (contactInfo) => {
    const response = await updateContactInfo({
      variables: {
        input: contactInfo,
      },
    });

    if (response && response.data && response.data.updateContactInfo) {
      setUserContacts((current) =>
        current.map((contact) => {
          if (contact.id === contactInfo.id) {
            return contactInfo;
          }
          return contact;
        })
      );
    }
  };

  const addUserContact = async (contactInfo) => {
    const response = await createContactInfo({
      variables: {
        input: contactInfo,
      },
    });

    if (response && response.data && response.data.createContactInfo) {
      setUserContacts((current) => [...current, response.data.createContactInfo]);
    }
  };
  return (
    <>
      <Box className={styles.dataForm}>
        <Box className={styles.contactHeader}>
          <Typography className={styles.contactTitle}>Contact information</Typography>
          {userContacts.length > 0 ? (
            <Button
              type={windowSize.width >= 600 ? 'link' : 'mainButton'}
              className={windowSize.width >= 600 && styles.addInfoBtn}
              onClick={() => setAddContactsModal(true)}
            >
              <Hidden smDown>
                <Box>My Contact Information</Box>
              </Hidden>
              <Hidden smUp>
                <Image src="/images/plus-blue.svg" width={16} height={24} />
              </Hidden>
            </Button>
          ) : (
            <Button
              type={windowSize.width >= 600 ? 'link' : 'mainButton'}
              className={windowSize.width >= 600 && styles.addInfoBtn}
              onClick={() => setContactItemModal('add')}
            >
              <Hidden smDown>
                <Image src="/images/plus-blue.svg" width={16} height={24} />
                <Typography className={styles.addInfoText}>add Contact information</Typography>
              </Hidden>
              <Hidden smUp>
                <Image src="/images/plus-blue.svg" width={16} height={24} />
              </Hidden>
            </Button>
          )}
        </Box>
        {selectedContact?.id && (
          <Box className={styles.dataFormFieldsContainer}>
            <Box className={styles.infos}>
              <Typography
                className={styles.infoText}
              >{`${selectedContact.first_name} ${selectedContact.last_name}`}</Typography>
              <Typography className={styles.infoText}>{selectedContact.tel}</Typography>
              <Typography className={styles.infoText}>{selectedContact.email}</Typography>
            </Box>
          </Box>
        )}
        <Box className={styles.contactHeader}>
          <Typography className={styles.contactTitle}>Delivery information</Typography>
          {selectedDelivery?.id ? (
            <Button
              type={windowSize.width >= 600 ? 'link' : 'mainButton'}
              className={windowSize.width >= 600 && styles.addInfoBtn}
              onClick={() => setAddDeliveryModal(true)}
            >
              <Hidden smDown>
                <Box>My Delivery Information</Box>
              </Hidden>
              <Hidden smUp>
                <Image src="/images/plus-blue.svg" width={16} height={24} />
              </Hidden>
            </Button>
          ) : (
            <Button
              type={windowSize.width >= 600 ? 'link' : 'mainButton'}
              className={windowSize.width >= 600 && styles.addInfoBtn}
              onClick={() => setDeliveryItemModal('add')}
            >
              <Hidden smDown>
                <Image src="/images/plus-blue.svg" width={16} height={24} />
                <Typography className={styles.addInfoText}>add Delivery information</Typography>
              </Hidden>
              <Hidden smUp>
                <Image src="/images/plus-blue.svg" width={16} height={24} />
              </Hidden>
            </Button>
          )}
        </Box>
        {selectedDelivery?.id && (
          <Box className={styles.infos}>
            <Typography className={styles.infoText}>
              {`${selectedDelivery.country}, ${selectedDelivery.town}`},
            </Typography>
            <Typography className={styles.infoText}>{selectedDelivery.address},</Typography>
            <Typography className={styles.infoText}>{selectedDelivery.index}</Typography>
          </Box>
        )}
        <Box>
          <TextField
            {...register('comment', {
              pattern: {
                value: /^.{1,350}$/gm,
                message: "You can't write more than 350 characters.",
              },
            })}
            rows={2}
            multiline
            inputProps={{ style: { resize: 'vertical' } }}
            className={styles.textarea}
            label="Comment"
            error={!!errors.comment}
            helperText={errors.comment?.message}
          />
        </Box>
      </Box>
      <FormProvider {...contactMethods}>
        <AddContactsModal
          contacts={userContacts}
          setContacts={setUserContacts}
          open={addContactsModal}
          methods={contactMethods}
          onClose={() => setAddContactsModal(false)}
          selectedContact={selectedContact}
          setSelectedContact={setSelectedContact}
          editUserContactInfo={editUserContactInfo}
          addUserContact={addUserContact}
        />
      </FormProvider>
      <FormProvider {...contactItemMethods}>
        <ContactItemModal
          contact={contactItemModal}
          onClose={() => setContactItemModal(null)}
          editOrderData={addUserContact}
          editUserContactInfo={editUserContactInfo}
          setSelectedContact={setSelectedContact}
          allContacts={userContacts}
          methods={contactItemMethods}
        />
      </FormProvider>
      <FormProvider {...deliveryMethods}>
        <AddDeliveriesModal
          deliveries={deliveries}
          methods={deliveryMethods}
          open={addDeliveryModal}
          onClose={() => setAddDeliveryModal(false)}
          setDeliveries={setDeliveries}
          selectedDelivery={selectedDelivery}
          setSelectedDelivery={setSelectedDelivery}
        />
      </FormProvider>
      <FormProvider {...deliveryItemMethods}>
        <DeliveryItemModal
          delivery={deliveryItemModal}
          onClose={() => setDeliveryItemModal(null)}
          setDeliveries={setDeliveries}
          setSelectedDelivery={setSelectedDelivery}
          allDeliveries={deliveries}
          methods={deliveryItemMethods}
        />
      </FormProvider>
    </>
  );
};

export default CheckoutContactForm;
