import React, { useEffect, useState } from 'react';
import { IconButton, Typography, Box } from '@mui/material';
import { Button, Loader } from 'tt-ui-kit';
import { openNotification } from 'tt-ui-lib/core';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import FavoriteIcon from '@mui/icons-material/Favorite';
import StarIcon from '@mui/icons-material/Star';
import styles from './ProductCard.module.scss';
import ChatBubbleOutlineIcon from '@mui/icons-material/ChatBubbleOutline';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import { useMutation } from '@apollo/client';
import { CREATE_AND_UPDATE_USER_CART } from '../../../../api';
import ItemCounter from '../ItemCounter';

export const ProductCard = (props) => {
  const {
    pressCard,
    editProduct,
    isSeller,
    existData,
    isCredit = false,
    onUpdate,
    user,
    handleLoginClick,
    pageType,
    middlePressCard,
    productType = 'product',
    viewBtnText = 'View',
    buyBtnText = 'BUY',
    onSilenceUpdate,
    fetchCartDataWidget,
    cartDeleteTriggered,
    setCartDeleteTriggered,
  } = props;

  const [clickedFavorite, setClickedFavorite] = useState(false);
  const [isInCart, setIsInCart] = useState(false);
  const [lastVisiblePrice, setLastVisiblePrice] = useState({});
  const [loadingBuy, setLoadingBuy] = useState(false);
  const [addItemToCartRequest] = useMutation(CREATE_AND_UPDATE_USER_CART);
  const [fontSize, setFontSize] = useState(16.5);

  const [imageError, setImageError] = useState(false);

  const [checkUpdate, setCheckUpdate] = useState(false);

  const handleImageError = () => {
    setImageError(true);
  };

  useEffect(() => {
    const calculateFontSize = () => {
      const newTextLength = lastVisiblePrice.price.toString().length;
      const newFontSize = newTextLength >= 6 ? 16.5 - newTextLength * 0.6 : 16.5;
      const minFontSize = 10;
      setFontSize(Math.max(newFontSize, minFontSize));
    };
    if (lastVisiblePrice.price) {
      calculateFontSize();
    }
  }, [lastVisiblePrice]);

  useEffect(() => {
    let isExistData = existData.productPrices?.some((item) => item.userCart?.quantity > 0);
    if (isExistData) {
      setIsInCart(true);
      setLoadingBuy(false);
    } else {
      setIsInCart(false);
      setLoadingBuy(false);
    }
  }, [existData]);

  useEffect(() => {
    if (existData?.lastVisiblePrice?.id) {
      setLastVisiblePrice(existData.lastVisiblePrice);
    }
  }, [existData]);

  const addItemToCart = () => {
    setLoadingBuy(true);
    (async () => {
      const input = {
        product_id: existData.id,
        quantity: existData.lastVisiblePrice?.min_order_quantity || 1,
        product_price_id: existData.lastVisiblePrice.id,
      };

      const req = await addItemToCartRequest({
        variables: {
          input: input,
        },
      });

      if (req?.data?.addToUserCart) {
        openNotification({
          type: 'success',
          message: 'Product added to your cart',
        });
        fetchCartDataWidget();
        onSilenceUpdate && onSilenceUpdate();
      } else {
        openNotification({
          type: 'error',
          message: 'Error while adding product to your cart, try again later',
        });
      }
    })();
  };

  const favoriteClick = (e) => {
    setClickedFavorite(!clickedFavorite);
    e.stopPropagation();
  };

  const getPrice = () => {
    if (productType === 'carbon') {
      return `${existData?.currency?.symbol || '$'} ${existData?.price || 0}`;
    }
    if (productType === 'service') {
      if (existData?.price_type === 'fixed') {
        return `${existData?.currency?.symbol || '$'} ${existData?.basic_price || 0}`;
      } else {
        return `${existData?.currency?.symbol || '$'} ${existData?.hourly_rate || 0}/h`;
      }
    }
    if (productType === 'product') {
      return `${lastVisiblePrice.currency_symbol || '$'} ${lastVisiblePrice.price || 0}`;
    }
  };

  const onPressCard = (event) => {
    if (event.button === 1) {
      middlePressCard();
    } else if (event.button === 0) {
      pressCard();
    } else {
      return;
    }
  };

  useEffect(() => {
    if (productType === 'product') {
      if (isInCart) {
        setCheckUpdate(true);
      }
      if (setCartDeleteTriggered) {
        setCartDeleteTriggered(false);
      }
    }
  }, [cartDeleteTriggered]);

  useEffect(() => {
    if (isInCart) {
      setLoadingBuy(true);
      setIsInCart(false);
      setCheckUpdate(false);
    }
  }, [checkUpdate]);

  return (
    <Box className={styles.cardContainerStyle} onMouseDown={onPressCard}>
      <Box className={styles.container}>
        <img
          src={
            !isCredit && existData.logos && existData.logos[0] && !imageError
              ? existData.logos[0].path
              : isCredit && existData.projectType && existData.projectType.logoPath
                ? existData.projectType.logoPath
                : '/images/market-place/no_image.png'
          }
          onError={handleImageError}
          alt=""
          role="presentation"
        />
        {user.id && isSeller ? (
          <IconButton className={styles.btnEdit} onMouseDown={(e) => editProduct(e)}>
            <ModeEditIcon fontSize="small" />
          </IconButton>
        ) : (
          <IconButton className={styles.btnFavorite} onMouseDown={(e) => favoriteClick(e)}>
            {clickedFavorite ? <FavoriteIcon style={{ color: 'red' }} /> : <FavoriteBorderIcon />}
          </IconButton>
        )}
      </Box>
      <Box className={styles.cardBody}>
        <Box className={styles.text}>
          <Typography sx={{ fontSize: '16px', fontWeight: 500, lineHeight: '24px' }}>
            {existData.name}
          </Typography>
          <Typography sx={{ fontSize: '12px', fontWeight: 400, color: '#757575', height: 16 }}>
            {existData?.company && (
              <a
                href={`/dashboard/company/${existData?.company?.id}/store`}
                target="_blank"
                rel="noreferrer"
                onMouseDown={(e) => e.stopPropagation()}
                style={{ color: 'inherit' }}
              >
                {existData?.company?.company_name || ''}
              </a>
            )}
          </Typography>
        </Box>
        <Box className={styles.productRatingBar}>
          {isCredit ? (
            <div className={styles.availableBlock}>
              Available:{' '}
              {existData.amount ? (
                <span>
                  {existData.amount} {existData?.unit?.name}
                </span>
              ) : (
                <span style={{ color: '#d32f2f' }}>Sold out</span>
              )}
            </div>
          ) : (
            <>
              <Box className={styles.ratingBarItems}>
                <IconButton sx={{ padding: '0px', color: '#F9CB00' }}>
                  <StarIcon />
                </IconButton>
                <Typography sx={{ fontSize: '14px', fontWeight: 400, color: '#424242' }}>
                  0
                </Typography>
              </Box>
              <Box className={styles.ratingBarItems}>
                <IconButton sx={{ padding: '0px', color: '#757575' }}>
                  <ChatBubbleOutlineIcon />
                </IconButton>
                <Typography sx={{ fontSize: '14px', fontWeight: 400, color: '#424242' }}>
                  0
                </Typography>
              </Box>
            </>
          )}
        </Box>
        {lastVisiblePrice && lastVisiblePrice.availability && (
          <Box className={styles.text}>
            <Typography
              sx={
                lastVisiblePrice.availability === 'available' ||
                lastVisiblePrice.availability === 'always available'
                  ? { color: '#2D8342' }
                  : { color: '#C4C4C4' }
              }
              className={styles.availability}
            >
              {lastVisiblePrice.availability?.replaceAll('_', ' ')}
            </Typography>
          </Box>
        )}
        {productType === 'service' && (
          <Typography className={styles.inProgressText}>2 in progress</Typography>
        )}
        <Box className={styles.buttonGroup}>
          <Box className={styles.priceBlock}>
            {productType === 'service' && (
              <Typography className={styles.inProgressText}>from</Typography>
            )}
            {lastVisiblePrice?.discount_price || existData?.discount_price ? (
              <>
                <Typography className={styles.oldPrice} style={{ fontSize: `${fontSize - 1}px` }}>
                  {productType === 'service'
                    ? `${existData.currency_symbol || '$'} ${existData.basic_price || 0}`
                    : `${lastVisiblePrice.currency_symbol || '$'} ${lastVisiblePrice.price || 0}`}
                </Typography>
                <Typography className={styles.discountPrice} style={{ fontSize: `${fontSize}px` }}>
                  {productType === 'service'
                    ? `${existData.currency_symbol || '$'} ${existData.discount_price || '-'}`
                    : `${lastVisiblePrice.currency_symbol || '$'} ${lastVisiblePrice.discount_price || '-'}`}
                </Typography>
              </>
            ) : (
              <Typography className={styles.price} style={{ fontSize: `${fontSize}px` }}>
                {getPrice()}
              </Typography>
            )}
          </Box>
          {user.id && isSeller ? (
            <Button type="default" className={styles.btn}>
              {viewBtnText}
            </Button>
          ) : isInCart ? (
            <ItemCounter
              id={existData.id}
              priceId={existData.lastVisiblePrice.id}
              unitName={existData.productPrices.find((item) => item.unit)?.unit?.name}
              initialCount={
                existData.productPrices.find((item) => item.userCart)?.userCart?.quantity
              }
              price={lastVisiblePrice}
              onUpdate={onUpdate}
              fetchCartDataWidget={fetchCartDataWidget}
            />
          ) : loadingBuy ? (
            <Box className={styles.loaderBox}>
              <Loader />
            </Box>
          ) : (
            <Button
              type="default"
              disabled={
                productType === 'service'
                  ? false
                  : isCredit
                    ? !existData.amount > 0
                    : !lastVisiblePrice.availableToBuy
              }
              className={styles.btn}
              onMouseDown={
                !user?.id
                  ? (e) => handleLoginClick(e)
                  : (e) => {
                      if (isCredit) return;

                      e.stopPropagation();
                      productType === 'service' ? pressCard() : addItemToCart();
                    }
              }
            >
              {buyBtnText}
            </Button>
          )}
        </Box>
      </Box>
    </Box>
  );
};
