import React, { useState } from 'react';
import styles from './Goal.module.scss';
import { Box, Typography } from '@mui/material';
import Image from '../../../../../Image';

const Goal = ({ item, key }) => {
  const [selectedGoal, setSelectedGoal] = useState(true);

  return (
    <Box key={key} className={styles.goalBlock}>
      <Box className={styles.goalTitle} onClick={() => setSelectedGoal(!selectedGoal)}>
        <Image
          key={key}
          src={`/images/company/${item.name}.svg`}
          width={28}
          height={28}
          style={{ borderRadius: '4px' }}
        />
        <Box className={styles.goalName}>{item.name.replaceAll('_', ' ')}</Box>
      </Box>
      {selectedGoal && item.description && (
        <Box className={styles.goalText}>
          <Typography>{item.description}</Typography>
        </Box>
      )}
    </Box>
  );
};

export default Goal;
