import React, { useEffect, useState } from 'react';
import { CartWidget } from '../../modules/tt-module-cart';

import { SHOW_USER_AVAILABLE_CARTS, REMOVE_ITEM_FROM_CART } from '../../api';

import { useLazyQuery, useMutation } from '@apollo/client';

export const CartWrapper = ({
  fetchCartDataWidget,
  cartWidgetItems,
  cartIsLoading,
  setCartDeleteTriggered,
}) => {
  const [deleteItemRequest] = useMutation(REMOVE_ITEM_FROM_CART);

  useEffect(() => {
    fetchCartDataWidget();
  }, []);

  return (
    <CartWidget
      cartItems={cartWidgetItems}
      deleteItemRequest={deleteItemRequest}
      fetchCartDataWidget={fetchCartDataWidget}
      cartIsLoading={cartIsLoading}
      setCartDeleteTriggered={setCartDeleteTriggered}
    />
  );
};
