import React from 'react';
import { Modal } from 'tt-ui-kit';

const ErrorPaymentModal = ({ open, onClose }) => {
  // TODO Поменять текст
  return (
    <Modal open={open} onClose={onClose} title="Payment is not possible now">
      <div>Payment is not possible now. Please try again later.</div>
    </Modal>
  );
};

export default ErrorPaymentModal;
