import styles from './aboutCompanyPage.module.scss';
import React from 'react';
import { HelpTooltip } from 'tt-ui-kit';
import { useFormContext } from 'react-hook-form';

export const AboutCompanyForm = () => {
  const {
    register,
    formState: { errors },
  } = useFormContext();

  return (
    <>
      <div className={styles.documentsBody}>
        <div className={styles.titleBlock}>
          <h2>About company</h2>
          <HelpTooltip
            tooltipText="This is public information"
            iconClassName={styles.helpIconStyle}
          />
        </div>
        <div>
          <textarea
            maxLength={500}
            className={styles.area}
            placeholder="Tell us more about your company"
            {...register('aboutCompany')}
          />
        </div>
      </div>
    </>
  );
};
