import React, { useContext, useRef, useState } from 'react';
import { Route, Routes } from 'react-router-dom';
import CompanyProfileRoute from '../../views/companyProfile';
import RegistrationTypeRoute from '../../views/registrationType';
import MapRoute from '../../views/map';
import ProductsCardRoute from '../../views/productsCard';
import Orders from '../../views/orders';
import { CompanyInfo } from '../CompanyPage';
import { CompanyItemPage } from '../CompanyItemPage';
import { Layout } from '../../components/layout';
import CompaniesListRoute from '../../views/companiesList';
import CreateProductRoute from '../../views/createProduct';
import CreateServiceRoute from '../../views/createService';
import { Products } from '../Products';
import { Cart } from '../Cart';
import { Marketplace } from '../Marketplace';
import { ProductAndServices } from '../ProductAndServices';
import { CarbonItem } from '../CarbonItem';
import { CarbonItemPreview } from '../CarbonItemPreview';
import { ServiceItem } from '../ServiceItem';
import { ProductItem } from '../ProductItem';
import { ProductItemPreview } from '../ProductItemPreview';
import ExternalAssessmentRoute from '../externalAssessment';
import CreditEditRoute from '../Proposals/CreditEdit';
import AuthWrapper from '../../components/AuthWarpper';
import ComingSoon from '../../components/ComingSoon';
import CompanyKyb from '../CompanyPage/CompanyKyb';
import { CarbonCart } from '../CarbonCart';
import { Order } from '../Order';
import { Payment } from '../Payment';
import CarbonCartDiscount from '../../components/pages/CarbonCartPage/DiscountView';
import { DigitalAssetsProvider } from '../../modules/tt-digital-assets-provider';
import userContext from '../../context/User/userContext';
import ResultCarbonCheckoutPage from '../../components/pages/CarbonCartPage/Result';
import { OrderReceipt } from '../OrderReceipt';
import { ServiceItemPreview } from '../ServiceItemPreview';
import { Layout404 } from 'tt-ui-kit';
import { CheckoutCart } from '../CheckoutCart';
import { SHOW_USER_AVAILABLE_CARTS } from '../../api';
import { useLazyQuery } from '@apollo/client';

const isProduction = process.env.REACT_APP_ENV === 'production';
const comingSoonText =
  "This section allocates the products and services of startups and experts onboarded on TransparenTerra. A unique marketplace is coming soon, and Opt-in to our email list to get updates. To create your company's online store, you should create your company profile. Create your Company Profile.";

const DashboardRoutes = () => {
  const { user } = useContext(userContext);
  const [scrollDown, setScrollDown] = useState(false);
  const [cartWidgetItems, setCartWidgetItems] = useState([]);
  const [cartIsLoading, setCartIsLoading] = useState(true);
  const [cartDeleteTriggered, setCartDeleteTriggered] = useState(false);
  const [getAvailableCarts] = useLazyQuery(SHOW_USER_AVAILABLE_CARTS, {
    fetchPolicy: 'network-only',
  });

  const divRef = useRef(null);

  const scrollToTop = () => {
    divRef.current.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const fetchCartDataWidget = async () => {
    if (user.id) {
      const availableRes = await getAvailableCarts({ variables: { is_checkout: false } });
      if (availableRes?.data?.showAvailableUserCarts.carts) {
        setCartWidgetItems(availableRes.data.showAvailableUserCarts.carts);
        setCartIsLoading(false);
      } else {
        setCartWidgetItems([]);
        setCartIsLoading(false);
      }
    }
  };

  const DASHBOARD_ROUTES_INFO = [
    {
      path: '/map',
      name: 'Map',
      element: <MapRoute />,
    },
    // {
    //   path: '/products',
    //   element: <ProductsRoute />,
    // },
    {
      path: '/marketplace',
      name: 'Marketplace',
      element: <Products />,
    },
    {
      path: '/orders/:orderType?',
      name: 'Orders',
      element: isProduction ? (
        <ComingSoon text={comingSoonText} />
      ) : (
        <AuthWrapper reloadAfterLogin>
          <Orders />
        </AuthWrapper>
      ),
    },
    {
      path: '/cart',
      name: 'Cart',
      element: isProduction ? (
        <ComingSoon text={comingSoonText} />
      ) : (
        <AuthWrapper reloadAfterLogin>
          <Cart
            scrollDown={scrollDown}
            scrollToTop={scrollToTop}
            fetchCartDataWidget={fetchCartDataWidget}
            cartWidgetItems={cartWidgetItems}
          />
        </AuthWrapper>
      ),
    },
    {
      path: '/checkout',
      name: 'Checkout',
      element: isProduction ? (
        <ComingSoon text={comingSoonText} />
      ) : (
        <AuthWrapper reloadAfterLogin>
          <CheckoutCart />
        </AuthWrapper>
      ),
    },
    {
      path: '/payment/:id',
      name: 'Payment',
      element: isProduction ? (
        <ComingSoon text={comingSoonText} />
      ) : (
        <AuthWrapper reloadAfterLogin>
          <Payment />
        </AuthWrapper>
      ),
    },
    {
      path: '/orders/outgoing/:id',
      name: 'Order',
      element: isProduction ? (
        <ComingSoon text={comingSoonText} />
      ) : (
        <AuthWrapper reloadAfterLogin>
          <Order orderViewer="customer" />
        </AuthWrapper>
      ),
    },
    {
      path: '/orders/incoming/:id',
      name: 'Order',
      element: isProduction ? (
        <ComingSoon text={comingSoonText} />
      ) : (
        <AuthWrapper reloadAfterLogin>
          <Order orderViewer="seller" />
        </AuthWrapper>
      ),
    },
    {
      path: '/orders/incoming/:id/receipt',
      name: 'Order',
      element: isProduction ? (
        <ComingSoon text={comingSoonText} />
      ) : (
        <AuthWrapper reloadAfterLogin>
          <OrderReceipt orderViewer="seller" />
        </AuthWrapper>
      ),
    },
    {
      path: '/orders/outgoing/:id/success',
      name: 'Order',
      element: isProduction ? (
        <ComingSoon text={comingSoonText} />
      ) : (
        <AuthWrapper reloadAfterLogin>
          <Order paymentStatus="success" orderViewer="customer" />
        </AuthWrapper>
      ),
    },
    {
      path: '/orders/outgoing/:id/receipt',
      name: 'Order',
      element: isProduction ? (
        <ComingSoon text={comingSoonText} />
      ) : (
        <AuthWrapper reloadAfterLogin>
          <OrderReceipt orderViewer="customer" />
        </AuthWrapper>
      ),
    },
    {
      path: '/marketplace/list/product',
      name: 'Marketplace list',
      element: (
        <ProductAndServices
          cartWidgetItems={cartWidgetItems}
          isProduction={isProduction}
          fetchCartDataWidget={fetchCartDataWidget}
          cartDeleteTriggered={cartDeleteTriggered}
          setCartDeleteTriggered={setCartDeleteTriggered}
        />
      ),
    },
    {
      path: '/credit/:id',
      name: 'Carbon credit',
      element: <CarbonItem />,
    },
    {
      path: '/credit/:id/preview',
      name: 'Carbon credit',
      element: <CarbonItemPreview />,
    },
    {
      path: '/settings',
      name: 'Settings and security',
      element: <ComingSoon text={comingSoonText} />,
    },
    {
      path: '/help',
      name: 'Help',
      element: <ComingSoon text={comingSoonText} />,
    },
    {
      path: '/credit/:id/checkout',
      name: 'Carbon Cart',
      element: (
        <AuthWrapper reloadAfterLogin>
          <CarbonCart />
        </AuthWrapper>
      ),
    },
    {
      path: '/credit/:id/checkout/discount',
      name: 'Carbon Cart',
      element: (
        <AuthWrapper reloadAfterLogin>
          <CarbonCartDiscount />
        </AuthWrapper>
      ),
    },
    {
      path: '/credit/:id/checkout/result/:status',
      name: 'Carbon Cart',
      element: (
        <AuthWrapper reloadAfterLogin>
          <ResultCarbonCheckoutPage />
        </AuthWrapper>
      ),
    },
    {
      path: '/product/:id/preview',
      name: 'Product',
      element: isProduction ? (
        <ComingSoon text={comingSoonText} />
      ) : (
        <AuthWrapper reloadAfterLogin>
          <ProductItemPreview />
        </AuthWrapper>
      ),
    },
    {
      path: '/product/:id',
      name: 'Product',
      element: isProduction ? (
        <ComingSoon text={comingSoonText} />
      ) : (
        <AuthWrapper reloadAfterLogin>
          <ProductItem
            fetchCartDataWidget={fetchCartDataWidget}
            cartDeleteTriggered={cartDeleteTriggered}
            setCartDeleteTriggered={setCartDeleteTriggered}
          />
        </AuthWrapper>
      ),
    },
    {
      path: '/service/:id',
      name: 'Service',
      element: isProduction ? <ComingSoon text={comingSoonText} /> : <ServiceItem />,
    },
    {
      path: '/service/:id/preview',
      name: 'Service',
      element: isProduction ? <ComingSoon text={comingSoonText} /> : <ServiceItemPreview />,
    },
    {
      path: '/productsCard',
      name: 'Products',
      element: isProduction ? <ComingSoon text={comingSoonText} /> : <ProductsCardRoute />,
    },
    {
      path: '/companyProfile',
      name: 'Add company',
      element: (
        <AuthWrapper reloadAfterLogin>
          <RegistrationTypeRoute />
        </AuthWrapper>
      ),
    },
    {
      path: '/companyProfile/representative',
      name: 'Representative',
      element: (
        <AuthWrapper reloadAfterLogin>
          <CompanyProfileRoute />
        </AuthWrapper>
      ),
    },
    {
      path: '/companyProfile/externalAssessment',
      name: 'External assessment',
      element: (
        <AuthWrapper reloadAfterLogin>
          <ExternalAssessmentRoute />
        </AuthWrapper>
      ),
    },
    {
      path: '/company/:id/edit',
      name: 'Company Edit',
      element: (
        <AuthWrapper reloadAfterLogin>
          <CompanyInfo />
        </AuthWrapper>
      ),
    },
    {
      path: '/company/:id/edit/kyb',
      name: 'Company KYB',
      element: isProduction ? (
        <ComingSoon text={comingSoonText} />
      ) : (
        <AuthWrapper reloadAfterLogin>
          <CompanyKyb />
        </AuthWrapper>
      ),
    },
    {
      path: '/company/:id',
      name: 'Company',
      element: <CompanyItemPage />,
    },
    {
      path: '/companiesList',
      name: 'Companies List',
      element: (
        <AuthWrapper reloadAfterLogin>
          <CompaniesListRoute />
        </AuthWrapper>
      ),
    },
    {
      path: '/proposals/product/:action',
      name: 'Add proposal',
      element: isProduction ? (
        <ComingSoon text={comingSoonText} />
      ) : (
        <AuthWrapper reloadAfterLogin>
          <CreateProductRoute />
        </AuthWrapper>
      ),
    },
    {
      path: '/proposals/service/:action',
      name: 'Add service',
      element: isProduction ? (
        <ComingSoon text={comingSoonText} />
      ) : (
        <AuthWrapper reloadAfterLogin>
          <CreateServiceRoute />
        </AuthWrapper>
      ),
    },
    {
      path: '/proposals/credit/add',
      name: 'Carbon credit create',
      element: <CreditEditRoute />,
    },
    {
      path: '/proposals/credit/edit/:id',
      name: 'Carbon credit edit',
      element: <CreditEditRoute />,
    },
    {
      path: '/company/:companyId/store',
      name: 'Store',
      element: <Marketplace isProduction={isProduction} cartWidgetItems={cartWidgetItems} />,
    },
    {
      path: '*',
      name: 'Not Found',
      element: <Layout404 style={{ zIndex: 99999999 }} />,
    },
  ];

  return (
    <DigitalAssetsProvider userContextObject={userContext}>
      <Layout
        routes={DASHBOARD_ROUTES_INFO}
        inviteText={
          'Invite to TransparenTerra the people you trust and respect. Trust is a basic element of the TransparenTerra platform.'
        }
        savedImgs={[
          {
            text: 'Products & Services',
            src: '/images/businessCard.png',
          },
          {
            text: 'Experts & Investors',
            src: '/images/expertsCard.png',
          },
        ]}
        profilAvaImg={{
          text: 'BSN',
          img: { src: '/images/Vector.png' },
          textColor: '#00c853',
        }}
        extendedAva
        divRef={divRef}
        setScrollDown={setScrollDown}
        fetchCartDataWidget={fetchCartDataWidget}
        cartIsLoading={cartIsLoading}
        cartWidgetItems={cartWidgetItems}
        setCartDeleteTriggered={setCartDeleteTriggered}
      >
        <Routes>
          {DASHBOARD_ROUTES_INFO.map((routeInfo) => (
            <Route path={routeInfo.path} element={routeInfo.element} key={routeInfo.path} />
          ))}
        </Routes>
      </Layout>
    </DigitalAssetsProvider>
  );
};

export default DashboardRoutes;
