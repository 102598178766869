import React, { useContext } from 'react';
import userContext from '../../context/User/userContext';
import { useLocation } from 'react-router-dom';
import { PersonalAreaBox } from '../../modules/tt-left-menu';
// import 'tt-left-menu/dist/index.css';

const PersonalAreaBoxWrapper = (props) => {
  const {
    firstNameProps,
    lastNameProps,
    avaProps,
    inviteProps,
    ceoProps,
    kycProps,
    linksProps,
    contentProps,
    marketProps,
    calcProps,
    ...restProps
  } = props;

  const { pathname } = useLocation();
  const { user } = useContext(userContext);

  return (
    <PersonalAreaBox
      user={user}
      pathname={pathname}
      firstNameProps={firstNameProps}
      lastNameProps={lastNameProps}
      avaProps={avaProps}
      inviteProps={inviteProps}
      ceoProps={ceoProps}
      kycProps={kycProps}
      linksProps={linksProps}
      contentProps={contentProps}
      marketProps={marketProps}
      calcProps={calcProps}
      {...restProps}
    />
  );
};

export default PersonalAreaBoxWrapper;
