import React, { useContext, useEffect, useState } from 'react';
import { Button, Modal, SquareOutIcon, Typography } from 'tt-ui-kit';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import styles from './ConfirmCustomizationModal.module.scss';
import userContext from '../../../../context/User/userContext';
import moment from 'moment';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { useWindowSize } from '../../../../modules/tt-modals/utils/useWindowSize';

const ConfirmCustomizationModal = ({
  openConfirmModal,
  setOpenConfirmModal,
  onSubmit,
  serviceData,
  customerData,
  submitLoading,
}) => {
  const { user } = useContext(userContext);
  const { width } = useWindowSize();
  const [isFullscreen, setIsFullscreen] = useState(false);
  const handleFullscreenToggle = () => {
    setIsFullscreen(!isFullscreen);
  };

  useEffect(() => {
    if (width < 620) {
      setIsFullscreen(false);
    }
  }, [width]);

  function calculateFutureDate(value, unit) {
    return moment().add(value, unit.toLowerCase()).format('MM.DD.YYYY');
  }

  const renderTermPeriodChanges = () => {
    const originalTermPeriod = calculateFutureDate(
      serviceData.performance_term_period,
      serviceData.performance_term_period_type
    );
    if (
      serviceData.performance_term_period === customerData.performance_term_period &&
      serviceData.performance_term_period_type === customerData.performance_term_period_type
    ) {
      return <span className={styles.textWithBg}>{originalTermPeriod}</span>;
    } else {
      const changedTermPeriod = calculateFutureDate(
        customerData.performance_term_period,
        customerData.performance_term_period_type
      );
      return (
        <>
          <span className={styles.textWithBg}>{originalTermPeriod}</span>
          <ArrowForwardIcon style={{ width: 16, height: 16, paddingTop: '5px' }} />
          <span className={styles.textWithBgRed}>{changedTermPeriod}</span>
        </>
      );
    }
  };

  const checkMilestonesEquals = () => {
    return customerData.milestones?.every((obj1) => {
      return serviceData.milestones?.some((obj2) => {
        return Object.keys(obj1).every((key) => obj1[key] === obj2[key]);
      });
    });
  };

  const renderChangedMilestones = () => {
    return customerData.milestones?.map((item, index) => (
      <Box className={styles.textWithBg} key={index}>
        <Typography className={styles.textBold}>
          {index + 1}. Milestone {index + 1}: ({calculateFutureDate(item.period, item.period_type)})
        </Typography>
        {item.description && <Typography className={styles.text}>{item.description}</Typography>}
      </Box>
    ));
  };

  const renderPriceChange = () => {
    const originalPrice =
      serviceData.discount_price ??
      serviceData.basic_price ??
      serviceData.hourly_discount_price ??
      serviceData.hourly_price;
    const changedPrice = customerData.basic_price;
    if (originalPrice === changedPrice) {
      return <span className={styles.textWithBg}>${originalPrice}</span>;
    } else {
      return (
        <>
          <span className={styles.textWithBg}>${originalPrice}</span>
          <ArrowForwardIcon style={{ width: 16, height: 16, paddingTop: '5px' }} />
          <span className={styles.textWithBgRed}>${changedPrice}</span>
        </>
      );
    }
  };

  const renderMilestoneItemsChanges = () => {
    const customerMilestones = customerData.milestones;
    const serviceMilestones = serviceData.milestones;
    return customerMilestones?.map((customerMilestone, index) => {
      if (customerMilestone.id) {
        const matchMilestoneInService = serviceMilestones?.find(
          (serviceMilestone) => serviceMilestone.id === customerMilestone.id
        );
        const isPriceChanged = matchMilestoneInService?.price !== customerMilestone.price;
        if (isPriceChanged) {
          return (
            <>
              <span className={styles.textWithBg}>
                ${matchMilestoneInService.price} for Milestone {index + 1}
              </span>
              <ArrowForwardIcon style={{ width: 16, height: 16, paddingTop: '5px' }} />
              <span className={styles.textWithBgRed}>
                ${customerMilestone.price} for Milestone {index + 1}
              </span>
            </>
          );
        } else {
          return (
            <>
              <span className={styles.textWithBg}>
                ${matchMilestoneInService.price} for Milestone {index + 1}
              </span>
            </>
          );
        }
      } else {
        return (
          <>
            <span className={styles.textWithBg}>
              ${customerMilestone.price} for Milestone {index + 1}
            </span>
          </>
        );
      }
    });
  };

  return (
    <Modal
      key={isFullscreen}
      fullScreenByAction={isFullscreen}
      open={openConfirmModal}
      onClose={() => setOpenConfirmModal(false)}
      onClick={(e) => e.stopPropagation()}
      title={'Customization Request'}
      className={`${styles.requestCustomizationModal} ${isFullscreen ? styles.fullscreen : null}`}
    >
      {width >= 620 && (
        <IconButton
          aria-label="resize"
          className={styles.resizeIcon}
          onClick={handleFullscreenToggle}
        >
          <SquareOutIcon style={{ width: 24, height: 24 }} />
        </IconButton>
      )}
      <Typography className={styles.infoText}>
        Review your changes. Fields in yellow show the original terms. Fields in pink show your
        requested changes. Once you press the ‘send’ button your request will be immediately
        delivered to the contractor who will have 72 hours to respond. You may contact the
        contractor privately to discuss your request.
      </Typography>
      <Box className={styles.changesContent} sx={isFullscreen ? '' : { height: '300px' }}>
        <Typography className={styles.contentTitle}>INDEPENDENT CONTRACTOR AGREEMENT</Typography>
        <Box className={styles.text}>
          <Box>
            (1){' '}
            <span className={styles.textWithBg}>
              {user.first_name} {user.last_name}
            </span>{' '}
            (Client)
          </Box>
          <Box>
            (2) <span className={styles.textWithBg}>{serviceData.company.company_name}</span>{' '}
            ((Contractor))
          </Box>
        </Box>
        <Typography className={styles.subTitle}>1. ENGAGEMENT</Typography>
        <Box className={styles.text}>
          <Box>
            The Client hereby engages the Contractor to provide the following services{' '}
            <span className={styles.textWithBg}>{serviceData.name}</span>
          </Box>
          <Box>
            <Typography className={styles.textWithBg}>
              Description: {serviceData.description}
            </Typography>
          </Box>
          <Box>(Services or Project).</Box>
        </Box>
        <Typography className={styles.subTitle}>2. TERM AND MILESTONES</Typography>
        <Typography>
          The Contractor shall start providing Services upon payment in accordance with paragraph 3
          below.
        </Typography>
        <Typography>
          The Services shall be completed not later than {renderTermPeriodChanges()}
          {!checkMilestonesEquals() &&
            'and the following milestones shall be completed on the following dates'}
        </Typography>
        {!checkMilestonesEquals() && renderChangedMilestones()}
        <Typography className={styles.subTitle}>3. PAYMENT</Typography>
        <Typography>
          The Services shall be charged on a
          <span className={styles.textWithBg}>
            {serviceData.basic_price ? 'fixed price' : 'hourly price'}
          </span>{' '}
          in the amount of
          {renderPriceChange()} including:
        </Typography>
        <Box sx={{ display: 'flex', gap: '4px', alignItems: 'center', flexWrap: 'wrap' }}>
          {renderMilestoneItemsChanges()}
        </Box>
        <Typography>
          The payment of
          {renderPriceChange()} shall be made within 24 hours after this agreement is signed.
          TransparenTerra payment procedures shall apply. If payment is not made within 24 hours the
          agreement will be automatically terminated.
        </Typography>
        <Typography>
          The payment is made online via a duly licensed third-party - TransparenTerra Payment Agent
          and in accordance with its payment processing terms (including the payment processing
          fees).
        </Typography>
        <Typography>
          The TT commission fee of 7% of the service price is charged to the Contractor and will be
          deducted by the platform from the amount(s) due to the Contractor.
        </Typography>
      </Box>
      <Box className={styles.formButtons}>
        <Button
          className={styles.formButton}
          onClick={() => setOpenConfirmModal(false)}
          type="mainButton"
        >
          Reject
        </Button>
        <Button
          onClick={onSubmit}
          loading={submitLoading}
          disabled={submitLoading}
          className={styles.formButton}
          type="primary"
        >
          SIGN AND CONFIRM
        </Button>
      </Box>
    </Modal>
  );
};

export default ConfirmCustomizationModal;
