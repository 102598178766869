import React, { useState } from 'react';
import Box from '@mui/material/Box';
import {
  CardCvcElement,
  CardExpiryElement,
  CardNumberElement,
  useElements,
  useStripe,
} from '@stripe/react-stripe-js';
import styles from './AddCardForm.module.scss';
import { Button } from 'tt-ui-kit';
import { useMutation } from '@apollo/client';
import { ATTACH_PAYMENT_METHOD_TO_CUSTOMER } from '../../api';
import { openNotification } from 'tt-ui-lib/core';

const AddCardForm = ({ setCardsList, closeModal }) => {
  const stripe = useStripe();
  const elements = useElements();
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [attachPaymentMethod] = useMutation(ATTACH_PAYMENT_METHOD_TO_CUSTOMER);

  const handleSubmit = async (event) => {
    setIsSubmitted(true);
    event.preventDefault();

    if (!stripe || !elements) {
      console.error('Stripe or Elements not loaded');
      setIsSubmitted(false);
      return;
    }

    const cardNumberElement = elements.getElement(CardNumberElement);

    if (!cardNumberElement) {
      console.error('CardNumberElement not found');
      setIsSubmitted(false);
      return;
    }

    try {
      const { paymentMethod, error } = await stripe.createPaymentMethod({
        type: 'card',
        card: cardNumberElement,
      });
      if (paymentMethod && paymentMethod.id) {
        const response = await attachPaymentMethod({
          variables: { input: { id: paymentMethod.id, payment_system: 'stripe' } },
        });
        if (response.data && response.data.attachPaymentMethodToCustomerAccount) {
          setCardsList((current) => {
            const updatedCurrent = current.map((card) => ({
              ...card,
              is_default: false,
            }));

            return [
              {
                ...response.data.attachPaymentMethodToCustomerAccount,
                is_default: true,
              },
              ...updatedCurrent,
            ];
          });
        }
        openNotification({
          type: 'success',
          message: 'Card created successfully',
        });
        closeModal();
      }

      if (error) {
        console.error('Payment method creation failed:', error);
        setIsSubmitted(false);
        return;
      }
    } catch (error) {
      console.error('Unexpected error:', error);
      setIsSubmitted(false);
    }
    setIsSubmitted(false);
  };

  const cardElementOptions = {
    placeholder: 'Card number',
    showIcon: true,
    iconStyle: 'solid',
    style: {
      base: {
        iconColor: '#757575',
        fontWeight: '500',
        color: '#212121',
        fontFamily: 'Montserrat, sans-serif',
        fontSize: '16px',
        fontSmoothing: 'antialiased',
        '::placeholder': {
          color: '#757575',
        },
      },
      invalid: {
        color: '#9e2146',
      },
    },
  };
  const cardExpiryOptions = {
    placeholder: 'Expiration date',
    style: {
      base: {
        fontWeight: '500',
        color: '#212121',
        fontFamily: 'Montserrat, sans-serif',
        fontSize: '16px',
        fontSmoothing: 'antialiased',
        '::placeholder': {
          color: '#757575',
        },
      },
      invalid: {
        color: '#9e2146',
      },
    },
  };
  const cardCvcOptions = {
    placeholder: 'CVC / CVV',
    style: {
      base: {
        fontWeight: '500',
        color: '#212121',
        fontFamily: 'Montserrat, sans-serif',
        fontSize: '16px',
        fontSmoothing: 'antialiased',
        '::placeholder': {
          color: '#757575',
        },
      },
      invalid: {
        color: '#9e2146',
      },
    },
  };

  return (
    <form action="" onSubmit={handleSubmit} className={styles.form}>
      <CardNumberElement className={styles.cardNumber} options={cardElementOptions} />
      <Box className={styles.inputsRow}>
        <CardExpiryElement className={styles.cardNumber} options={cardExpiryOptions} />
        <CardCvcElement className={styles.cardNumber} options={cardCvcOptions} />
      </Box>
      <Box className={styles.actions}>
        <Button type="mainButton">CANCEL</Button>
        <Button type="primary" isSubmit loading={isSubmitted} disabled={isSubmitted}>
          SUBMIT
        </Button>
      </Box>
    </form>
  );
};

export default AddCardForm;
