import React, { useEffect, useState } from 'react';
import styles from './FilterModal.module.scss';
import Image from '../../../../../components/Image';
import { Controller } from 'react-hook-form';
import {
  Drawer,
  Loader,
  Collapse,
  Button,
  Input,
  InputNumber,
  Select,
  CheckBox,
} from 'tt-ui-lib/core';
import { SearchIcon } from 'tt-ui-lib/icons';
import Switch from '@mui/material/Switch';
import { styled } from 'tt-ui-kit';

const IOSSwitch = styled((props) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 40,
  height: 24,
  padding: 0,
  '& .MuiSwitch-switchBase': {
    padding: 0,
    margin: 4,
    transitionDuration: '300ms',
    color: '#555555',
    '&.Mui-checked': {
      transform: 'translateX(16px)',
      color: '#ffffff',
      '& + .MuiSwitch-track': {
        backgroundColor: '#18397A',
        opacity: 1,
        border: '1px solid #18397A',
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: 0.5,
      },
    },
    '&.Mui-focusVisible .MuiSwitch-thumb': {
      color: '#18397A',
      border: '6px solid #fff',
    },
    '&.Mui-disabled .MuiSwitch-thumb': {
      color: theme.palette.mode === 'light' ? theme.palette.grey[100] : theme.palette.grey[600],
    },
    '&.Mui-disabled + .MuiSwitch-track': {
      opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
    },
  },
  '& .MuiSwitch-thumb': {
    boxSizing: 'border-box',
    width: 16,
    height: 16,
  },
  '& .MuiSwitch-track': {
    borderRadius: 24 / 2,
    backgroundColor: 'transparent',
    border: '1px solid #555555',
    opacity: 1,
    transition: theme.transitions.create(['background-color'], {
      duration: 500,
    }),
  },
}));

const GOALS = [
  'no_poverty',
  'zero_hunger',
  'good_health',
  'quality_education',
  'gender_quality',
  'clear_water',
  'clean_energy',
  'economic_growth',
  'industry',
  'reduce_inequality',
  'sustainable_community',
  'responsible_consumption',
  'climate_action',
  'life_below_water',
  'life_on_land',
  'peace',
  'partnership',
];

const eventFormatFilter = [
  { name: 'Offline', value: 'offline' },
  { name: 'Online', value: 'online' },
];

export const FilterModal = ({
  open,
  activeTab,
  handleClose,
  show,
  cleanFilter,
  watch,
  control,
  getValues,
  setValue,
  countriesList,
  categoriesList,
  productsGroup,
  goals,
  setGoals,
  isCredit,
  onUpdate,
}) => {
  const [filteredCountriesList, setFilteredCountriesList] = useState([]);
  const [searchCountry, setSearchCountry] = useState('');
  const [filteredCategories, setFilteredCategories] = useState([]);
  const [searchCategory, setSearchCategory] = useState('');
  const [filteredProducts, setFilteredProducts] = useState([]);
  const [searchProduct, setSearchProduct] = useState('');
  const orderByFilter = [
    { label: 'Newest', value: 'created_at' },
    { label: 'Price: Low to High', value: 'priceHigh' },
    { label: 'Price: High to Low', value: 'priceLow' },
  ];
  const selectGoal = (id) => {
    const isExist = goals.find((value) => value === id);

    if (isExist) {
      setGoals(goals.filter((value) => value !== id));
    } else {
      setGoals([...goals, id]);
    }
  };

  useEffect(() => {
    setFilteredCountriesList(
      countriesList.filter((country) => {
        if (country.name.toUpperCase().startsWith(searchCountry.toUpperCase())) {
          return country;
        }
      })
    );
  }, [searchCountry]);

  useEffect(() => {
    setFilteredCategories(
      categoriesList.filter((category) => {
        if (category.name.toUpperCase().startsWith(searchCategory.toUpperCase())) {
          return category;
        }
      })
    );
  }, [searchCategory]);

  useEffect(() => {
    setFilteredProducts(
      productsGroup.filter((product) => {
        if (product.name.toUpperCase().startsWith(searchProduct.toUpperCase())) {
          return product;
        }
      })
    );
  }, [searchProduct]);

  const collapseItems = [
    {
      label: activeTab === 1 ? 'Location' : 'Region',
      key: '1',
      children: (
        <div className={styles.content}>
          <Input
            label="Search"
            prefix={<SearchIcon style={{ width: 20, height: 20 }} />}
            onChange={(e) => {
              setSearchCountry(e.target.value);
            }}
            value={searchCountry}
          />
          <div
            className={styles.formGroup}
            key={searchCountry}
            style={!(countriesList && countriesList.length > 0) ? { overflow: 'hidden' } : {}}
          >
            <div className={styles.CheckboxGroup}>
              {countriesList && countriesList.length > 0 ? (
                (searchCountry ? filteredCountriesList : countriesList).map((country) => (
                  <Controller
                    key={country.id}
                    className={styles.checkBoxList}
                    name={`country[${country.id}]`}
                    control={control}
                    defaultValue={false}
                    render={({ field: { onChange, value } }) => (
                      <CheckBox
                        label={country.name}
                        value={value}
                        onChange={(e) => {
                          onChange(e);
                          let checkedList = getValues('country_id') || [];
                          if (checkedList.includes(country.id)) {
                            checkedList = checkedList.filter(
                              (checkedItem) => checkedItem !== country.id
                            );
                            setValue('country_id', checkedList);
                          } else {
                            setValue('country_id', [...checkedList, country.id]);
                          }
                        }}
                      />
                    )}
                  />
                ))
              ) : (
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    overflow: 'hidden',
                  }}
                >
                  <Loader />
                </div>
              )}
            </div>
          </div>
        </div>
      ),
    },
    {
      label: 'Event Format',
      key: '2',
      children: (
        <div className={styles.content}>
          <div
            className={styles.formGroup}
            key={searchCountry}
            style={
              !(eventFormatFilter && eventFormatFilter.length > 0) ? { overflow: 'hidden' } : {}
            }
          >
            <div className={styles.CheckboxGroup}>
              {eventFormatFilter && eventFormatFilter.length > 0 ? (
                eventFormatFilter.map((event) => (
                  <Controller
                    key={event.value}
                    className={styles.checkBoxList}
                    name={`eventFormat[${event.value}]`}
                    control={control}
                    defaultValue={false}
                    render={({ field: { onChange, value } }) => (
                      <CheckBox
                        label={event.name}
                        value={value}
                        onChange={(e) => {
                          onChange(e);
                        }}
                      />
                    )}
                  />
                ))
              ) : (
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    overflow: 'hidden',
                  }}
                >
                  <Loader />
                </div>
              )}
            </div>
          </div>
        </div>
      ),
    },
    {
      label: 'Product Group',
      key: '3',
      children: (
        <div className={styles.content}>
          <Input
            label="Search"
            prefix={<SearchIcon style={{ width: 20, height: 20 }} />}
            onChange={(e) => {
              setSearchProduct(e.target.value);
            }}
          />
          <div className={styles.formGroup}>
            <div
              className={styles.CheckboxGroup}
              style={!(productsGroup && productsGroup.length > 0) ? { overflow: 'hidden' } : {}}
            >
              {productsGroup && productsGroup.length > 0 ? (
                (searchProduct ? filteredProducts : productsGroup).map((product) => (
                  <Controller
                    key={product.id}
                    className={styles.checkBoxList}
                    name={`group[${product.id}]`}
                    control={control}
                    defaultValue={false}
                    render={({ field: { onChange, value } }) => (
                      <CheckBox
                        label={product.name}
                        value={value}
                        onChange={(e) => {
                          onChange(e);
                          let checkedList = getValues('group_id') || [];
                          if (checkedList.includes(product.id)) {
                            checkedList = checkedList.filter(
                              (checkedItem) => checkedItem !== product.id
                            );
                            setValue('group_id', checkedList);
                          } else {
                            setValue('group_id', [...checkedList, product.id]);
                          }
                        }}
                      />
                    )}
                  />
                ))
              ) : (
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    overflow: 'hidden',
                  }}
                >
                  <Loader />
                </div>
              )}
            </div>
          </div>
        </div>
      ),
    },
    {
      label: 'Category',
      key: '4',
      children: (
        <div className={styles.content}>
          <Input
            label="Search"
            prefix={<SearchIcon style={{ width: 20, height: 20 }} />}
            onChange={(e) => {
              setSearchCategory(e.target.value);
            }}
          />
          <div
            className={styles.formGroup}
            style={!(categoriesList && categoriesList.length > 0) ? { overflow: 'hidden' } : {}}
          >
            <div className={styles.CheckboxGroup}>
              {categoriesList && categoriesList.length > 0 ? (
                (searchCategory ? filteredCategories : categoriesList).map((category) => (
                  <Controller
                    key={category.id}
                    className={styles.checkBoxList}
                    name={`category[${category.id}]`}
                    control={control}
                    defaultValue={false}
                    render={({ field: { onChange, value } }) => (
                      <CheckBox
                        label={category.name}
                        value={value}
                        onChange={(e) => {
                          onChange(e);
                          let checkedList = getValues('category_id') || [];
                          if (checkedList.includes(category.id)) {
                            checkedList = checkedList.filter(
                              (checkedItem) => checkedItem !== category.id
                            );
                            setValue('category_id', checkedList);
                          } else {
                            setValue('category_id', [...checkedList, category.id]);
                          }
                        }}
                      />
                    )}
                  />
                ))
              ) : (
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    overflow: 'hidden',
                  }}
                >
                  <Loader />
                </div>
              )}
            </div>
          </div>
        </div>
      ),
    },
    {
      label: 'Goals',
      key: '5',
      children: (
        <div className={styles.goals}>
          {GOALS.map((item) => (
            <Image
              key={item}
              className={
                !!goals.find((id) => id === item)
                  ? styles.goalFilterItemSelected
                  : styles.goalFilterItem
              }
              src={`/images/company/${item}.svg`}
              width={28}
              height={28}
              onClick={() => selectGoal(item)}
            />
          ))}
        </div>
      ),
    },
  ];

  return (
    <Drawer
      title="Filters"
      anchor="right"
      open={open}
      onClose={handleClose}
      className={styles.drawer}
    >
      <div className={styles.filterModal}>
        <div className={styles.modalBody}>
          <div className={styles.content}>
            <p className={styles.contentTitle}>Sort by</p>
            <Controller
              name={'sort'}
              defaultValue=""
              control={control}
              render={({ field: { onChange, value } }) => (
                <Select
                  value={value || null}
                  label="Sort by"
                  onChange={(value, label) => {
                    onChange(value);
                    setValue('ordersBy', [
                      {
                        column: label.value === 'created_at' ? value : 'price',
                        direction: label.value === 'priceHigh' ? 'ASC' : 'DESC',
                      },
                    ]);
                  }}
                  options={orderByFilter}
                  allowClear
                />
              )}
            />
          </div>
          <div className={styles.content}>
            <p className={styles.contentTitle}>Price in $</p>
            <div className={styles.textInputGroup}>
              <InputNumber
                value={watch('price_min')}
                onChange={(value) => {
                  setValue('price_min', value);
                }}
                label="From"
              />
              <InputNumber
                value={watch('price_max')}
                onChange={(value) => {
                  setValue('price_max', value);
                }}
                label="Until"
              />
            </div>
          </div>
          {isCredit ? (
            <>
              <div className={styles.content}>
                <p className={styles.contentTitle}>Amount /t</p>
                <div className={styles.textInputGroup}>
                  <InputNumber
                    value={watch('amount_min')}
                    onChange={(value) => {
                      setValue('amount_min', value);
                    }}
                    label="From"
                  />
                  <InputNumber
                    value={watch('amount_max')}
                    onChange={(value) => {
                      setValue('amount_max', value);
                    }}
                    label="Until"
                  />
                </div>
              </div>

              <Collapse
                className={styles.collapse}
                items={collapseItems.filter((item) => item.key === '1' || item.key === '5')}
              />
            </>
          ) : (
            <>
              <div>
                <div className={styles.checkFilter}>
                  <p>Instock</p>
                  <IOSSwitch sx={{ m: 1 }} />
                </div>
                <div className={styles.checkFilter}>
                  <p>Preorder</p>
                  <IOSSwitch sx={{ m: 1 }} />
                </div>
                <div className={styles.checkFilter}>
                  <p>Discount</p>
                  <IOSSwitch sx={{ m: 1 }} />
                </div>
              </div>
              <Collapse
                className={styles.collapse}
                items={
                  activeTab !== 1
                    ? collapseItems.filter((item) => item.key !== '2' && item.key !== '5')
                    : !productsGroup || productsGroup.length === 0
                      ? collapseItems.filter((item) => item.key !== '3' && item.key !== '5')
                      : collapseItems.filter((item) => item.key !== '5')
                }
              />
            </>
          )}
        </div>

        <div className={styles.modalFooter}>
          <Button
            onClick={() => {
              cleanFilter();
              setTimeout(() => {
                handleClose();
                onUpdate();
              }, 100);
            }}
            type="default"
          >
            RESET FILTERS
          </Button>

          <Button
            onClick={() => {
              show(true, 1);
              setTimeout(() => {
                handleClose();
                onUpdate();
              }, 100);
            }}
            type="primary"
          >
            APPLY
          </Button>
        </div>
      </div>
    </Drawer>
  );
};
