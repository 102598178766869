import React from 'react';
import { Box } from '@mui/material';
import styles from './EmptyProductPage.module.scss';
import { Link } from 'react-router-dom';

const EmptyProductPage = () => {
  return (
    <Box className={styles.pageContentBox}>
      <Box className={styles.productContent}>
        <Box className={styles.deletedUser}>
          <Box className={styles.deletedText}>
            <p>This product no longer exists</p>
            <p>
              The given content is no longer available{` `}
              <Link to="/dashboard/marketplace/list/product?activeTab=products">
                TT Market page
              </Link>
            </p>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default EmptyProductPage;
