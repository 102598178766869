import React, { useContext, useEffect, useState } from 'react';
import { useLazyQuery, useMutation } from '@apollo/client';
import styles from './companyProfile.module.scss';
import FormStepper from '../../stepper/stepper';
import { ManagerForm } from './forms/managerForm';
import { SocialForm } from '../commonBusinessProfileStepsPage/forms/socialForm';
import { DocumentsUploadForm } from './forms/documentsUploadForm';
import { InvestForm } from './forms/investForm';
import { PlatformForm } from '../commonBusinessProfileStepsPage/forms/platformForm';
import { PaymentForm } from '../commonBusinessProfileStepsPage/forms/paymentForm';
import { FormProvider, useFieldArray, useForm } from 'react-hook-form';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import { Box } from '@mui/material';
import {
  CREATE_ADDRESS_LOCATION,
  CREATE_COMPANY,
  EDIT_ADDRESS_LOCATION,
  EDIT_COMPANY,
  EDIT_COMPANY_CONTACT_INFOS,
  EDIT_COMPANY_DELIVERY_METHODS,
  EDIT_COMPANY_GOALS,
  EDIT_COMPANY_SOCIAL_LINKS,
  EDIT_COMPANY_TAGS,
  EDIT_OR_DELETE_COMPANY_SERVICES,
  EDIT_WITH_DELETE_COMPANY_LOGOS,
  GET_AVAILABLE_CURRENCIES,
  GET_COMPANY_DELIVERY_METHOD_DATA,
  GET_COMPANY_EXTENDED_BY_ID,
  GET_COMPANY_SERVICES,
  GET_UNITS,
} from '../../../api';
import { useNavigate, useSearchParams } from 'react-router-dom';
import moment from 'moment/moment';
import { BusinessForm } from '../commonBusinessProfileStepsPage/forms/businessForm';
import userContext from '../../../context/User/userContext';
import { AddNewPickupModal } from './forms/deliveryForm/addNewPickupModal';
import PartialLoader from '../../layout/PartialLoader';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import { getAvatarPathBySize } from '../../../utils/appUtils';

dayjs.extend(utc);
dayjs.extend(timezone);
const FORM_STEPS = [
  {
    name: 'General Information',
  },
  {
    name: 'Administrator',
  },
  {
    name: 'Contact information',
  },
  {
    name: 'About company',
  },
  {
    name: 'Accepting payment methods',
  },
  // {
  //   name: 'Delivery methods',
  // },
  {
    name: 'SDGs',
  },
  {
    name: 'Open for investments',
  },
];

const CompanyProfilePage = () => {
  const navigate = useNavigate();
  const { user } = useContext(userContext);
  const [searchParams, setSearchParams] = useSearchParams();
  const [subStepOpen, setSubStepOpen] = useState(false);
  const [activeStep, setActiveStep] = useState(0);
  const [openErrorAlert, setOpenErrorAlert] = useState(false);
  const [openSuccessAlert, setOpenSuccessAlert] = useState(false);
  const [isErrorFill, setIsErrorFill] = useState(false);
  const [isDraft, setIsDraft] = useState(false);
  const [companyId, setCompanyId] = useState(null);
  const [searchParamsCompanyId, setSearchParamsCompanyId] = useState(null);
  const [contactsData, setContactsData] = useState([]);
  const [socialsData, setSocialsData] = useState([]);
  const [pickupsList, setPickupsList] = useState([]);
  const [serviceData, setServiceData] = useState([]);
  const [goalsList, setGoalsList] = useState([]);
  const [deliveryMethodData, setDeliveryMethodData] = useState(null);
  const [pickupData, setPickupData] = useState([]);
  const [file, setFile] = useState(null);
  const [createCompany] = useMutation(CREATE_COMPANY);
  const [createAddressLocation] = useMutation(CREATE_ADDRESS_LOCATION);
  const [editCompanyContactInfos] = useMutation(EDIT_COMPANY_CONTACT_INFOS);
  const [editCompanySocialLinks] = useMutation(EDIT_COMPANY_SOCIAL_LINKS);
  const [editCompanyTags] = useMutation(EDIT_COMPANY_TAGS);
  const [editOrDeleteServices] = useMutation(EDIT_OR_DELETE_COMPANY_SERVICES);
  const [editWithDeleteCompanyLogos] = useMutation(EDIT_WITH_DELETE_COMPANY_LOGOS);
  const [editCompanyDeliveryMethods] = useMutation(EDIT_COMPANY_DELIVERY_METHODS);
  const [editCompany] = useMutation(EDIT_COMPANY);
  const [editCompanyGoals] = useMutation(EDIT_COMPANY_GOALS);
  const [getUnits] = useLazyQuery(GET_UNITS);
  const [editAddressLocation] = useMutation(EDIT_ADDRESS_LOCATION);
  const [categories, setCategories] = useState([]);
  const [currenciesList, setCurrenciesList] = useState([]);
  const [selectedCurrency, setSelectedCurrency] = useState('');
  const [oldServiceList, setOldServiceList] = useState([]);
  const [unitsList, setUnitsList] = useState([]);
  const [companyAddressLocation, setCompanyAddressLocation] = useState(null);
  const [companyData, setCompanyData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [getCurrencies] = useLazyQuery(GET_AVAILABLE_CURRENCIES);
  const [getCompanyById] = useLazyQuery(GET_COMPANY_EXTENDED_BY_ID);
  const [getCompanyService] = useLazyQuery(GET_COMPANY_SERVICES);
  const [showAllCompanyDeliveryMethodData] = useLazyQuery(GET_COMPANY_DELIVERY_METHOD_DATA);
  const lastStepNumber = FORM_STEPS.length - 1;
  const [isNewPickupModalOpen, setIsNewPickupModalOpen] = useState(false);
  const [editPickupData, setEditPickupData] = useState({});
  const [editablePickupIndex, setEditablePickupIndex] = useState();
  const [isEditModal, setIsEditModal] = useState(false);

  const methods = useForm();
  const pickupMethods = useForm();

  const { append: pickupAppend, update: pickupUpdate } = useFieldArray({
    control: methods.control,
    keyName: 'key',
    name: 'pickupAddresses',
  });

  useEffect(() => {
    if (user.id) {
      if (
        user.role_name === 'individual' ||
        user.role_name === 'digital' ||
        user.kyc !== 'success'
      ) {
        navigate('/dashboard/feed');
      }
    }
  }, [user]);

  useEffect(() => {
    (async () => {
      setLoading(true);
      const searchParamsId = searchParams.get('companyId');
      if (user?.id && searchParamsId) {
        setSearchParamsCompanyId(searchParamsId);
        setCompanyId(searchParamsId);
        const response = await getCompanyById({
          variables: { id: searchParamsId },
          fetchPolicy: 'network-only',
        });
        if (response.data && response.data.showCompanyById) {
          const companyInfoData = response.data.showCompanyById;
          if (companyInfoData.user_id === user?.id) {
            setCompanyData(companyInfoData);
            if (companyInfoData.companyGoals) {
              setGoalsList(companyInfoData.companyGoals);
            }
            if (
              response.data.showCompanyById.logos &&
              response.data.showCompanyById.logos.length &&
              response.data.showCompanyById.logos[0].path
            ) {
              setFile(getAvatarPathBySize(response.data.showCompanyById.logos, 'path'));
            }
          } else {
            navigate(`/dashboard/company/${companyInfoData.id}`);
          }
        }
        if (response.data.showCompanyById.companyContactInfos) {
          setContactsData(response.data.showCompanyById.companyContactInfos);
        }
        if (response.data.showCompanyById.companySocialLinks) {
          setSocialsData(response.data.showCompanyById.companySocialLinks);
        }

        const deliveryMethodResponse = await showAllCompanyDeliveryMethodData({
          variables: {
            company_id: searchParamsId,
          },
          fetchPolicy: 'network-only',
        });
        if (
          deliveryMethodResponse.data &&
          deliveryMethodResponse.data.showAllCompanyDeliveryMethodData
        ) {
          setDeliveryMethodData(deliveryMethodResponse.data.showAllCompanyDeliveryMethodData);
        }

        const serviceResponse = await getCompanyService({
          variables: {
            company_id: searchParamsId,
          },
          fetchPolicy: 'network-only',
        });
        if (serviceResponse.data && serviceResponse.data.getCompanyService) {
          setServiceData(serviceResponse.data.getCompanyService);
        }
      }
      setLoading(false);
    })();
  }, [user, searchParams]);

  const getCurrenciesList = async () => {
    const res = await getCurrencies({ variables: { payment_system: 'stripe' } });
    if (res.data && res.data.getAvailableCurrencies) {
      setCurrenciesList(res.data.getAvailableCurrencies);
      setSelectedCurrency(res.data.getAvailableCurrencies[0].id);
    }
  };
  const getUnitsList = async () => {
    const units = await getUnits({ variables: { input: { type: 'length' } } });
    if (units.data && units.data.getUnits) {
      setUnitsList(units.data.getUnits);
    }
  };

  const handleSaveSocialForm = async (data) => {
    let accounts = data.accounts.map((account) => {
      const itemData = {
        email: account.email,
        tel: account.tel,
        department_name: account.department_name,
      };
      if (account.id) {
        itemData.id = account.id;
      }
      return itemData;
    });

    let socialLinks = Object.keys(data.social)
      .map((key) => ({
        ...data.social[key],
      }))
      .filter((item) => item.link !== '');

    const contactInfos = await editCompanyContactInfos({
      variables: {
        input: {
          company_id: companyId,
          companyInfos: accounts,
        },
      },
    });
    if (contactInfos.data && contactInfos.data.editCompanyContactInfos) {
      setContactsData(contactInfos.data.editCompanyContactInfos);
    } else if (contactInfos.errors) {
      setOpenErrorAlert(true);
    }

    const companySocialLinks = await editCompanySocialLinks({
      variables: {
        input: {
          company_id: companyId,
          socialLinks: socialLinks,
        },
      },
    });
    if (companySocialLinks.data && companySocialLinks.data.editCompanySocialLinks) {
      setSocialsData(companySocialLinks.data.editCompanySocialLinks);
    } else if (companySocialLinks.errors) {
      setOpenErrorAlert(true);
    }

    if (companySocialLinks.data && contactInfos.data) {
      setActiveStep((step) => step + 1);
    }
  };

  const handleSaveManagerForm = async (data) => {
    setActiveStep((step) => step + 1);
  };

  const createOrUpdateCompany = async (companyInput) => {
    if (companyData && companyData.id) {
      let editCompanyData = await editCompany({
        variables: {
          input: { ...companyInput, id: companyData.id },
        },
      });
      return editCompanyData.data.editCompany;
    } else {
      let createCompanyData = await createCompany({
        variables: {
          input: companyInput,
        },
      });
      return createCompanyData.data.createCompany;
    }
  };

  const createOrUpdateAddressLocation = async (addressLocationInput) => {
    if (companyAddressLocation) {
      let editAddressLocationData = await editAddressLocation({
        variables: {
          input: { ...addressLocationInput, id: companyAddressLocation.id },
        },
      });
      return editAddressLocationData.data.editAddressLocation;
    } else {
      let createAddressLocationData = await createAddressLocation({
        variables: {
          input: addressLocationInput,
        },
      });
      return createAddressLocationData.data.createAddressLocation;
    }
  };

  const handleSaveBusinessForm = async (data) => {
    try {
      if (new Date(data.established_date).getTime() < -14400000) {
        methods.setError('established_date', {
          type: 'custom',
          message: 'Invalid date',
        });
        return false;
      } else {
        methods.clearErrors('established_date');
      }
      let addressLocationData = await createOrUpdateAddressLocation(data.location);
      if (addressLocationData) {
        setCompanyAddressLocation(addressLocationData);
        let companyInput = {
          tax_vat: data.tax_vat,
          email: data.email,
          established_date: moment(data.established_date).format('YYYY-MM-DD HH:mm:ss'),
          company_name: data.companyName,
          for_profit: data.for_profit === '1',
          industry_group_id: data.industry_group,
          sub_industry_id: data.subindustry,
          is_governmental: data.governmentalOrganization,
          address_location_id: addressLocationData.id,
          address1: addressLocationData.address,
          address2: data.address2,
          legal_type_id: data.legalType,
          website: data.website,
          step_number: activeStep,
          type: 'company',
        };
        let company = await createOrUpdateCompany(companyInput);
        if (company) {
          setCompanyData(company);
          if (file && typeof file !== 'string') {
            await editWithDeleteCompanyLogos({
              variables: {
                input: {
                  company_id: company.id,
                  files: [
                    {
                      original_name: file.originalName,
                      uuid_name: file.uuIdName,
                      extension: file.extension,
                      size: file.size,
                      visibility: 'public',
                      type: 'company_logo_main',
                    },
                  ],
                },
              },
            });
          }
        }

        if (company) {
          setCompanyId(company.id);
          setActiveStep((step) => step + 1);
        }
      }
    } catch (e) {
      console.error(e);
      setOpenErrorAlert(true);
    }
  };

  const handleSaveDocumentsForm = async (data) => {
    if (data.aboutCompany) {
      await editCompany({
        variables: {
          input: {
            id: companyId,
            description: data.aboutCompany,
          },
        },
      });
    }
    if (data.tags && data.tags.length > 0) {
      await editCompanyTags({
        variables: {
          input: {
            tags: data.tags,
            company_id: companyId,
          },
        },
      });
    }

    if (data.service && data.service.length > 0) {
      let services = data.service.filter((item) => item.serviceName && item.category);
      let sortedServices = services.map((item) => {
        let serviceItem = {
          name: item.serviceName,
          description: item.aboutService,
          category_id: item.category,
        };
        if (item.id) {
          serviceItem.id = item.id;
        }
        return serviceItem;
      });

      if ((sortedServices && sortedServices.length) || oldServiceList.length) {
        const createServices = await editOrDeleteServices({
          variables: {
            input: {
              company_id: companyId,
              services: sortedServices,
            },
          },
        });
      }
      setServiceData(sortedServices);
      setOldServiceList(sortedServices);
    }
    setActiveStep((step) => step + 1);
  };

  const handleSavePaymentForm = async (data) => {
    await getCurrenciesList();
    await getUnitsList();
    setActiveStep((step) => step + 1);
  };

  const handleSaveDeliveryForm = async (data) => {
    if (data.delivery.delivery_pickup && data.pickupAddresses.length === 0) {
      return false;
    }
    let pickupData = [];
    if (data.delivery.delivery_pickup) {
      pickupData = data.pickupAddresses.map((item) => {
        const contactInfo = {};
        if (item.contact_person) contactInfo.contact_person = item.contact_person;
        if (item.email) contactInfo.email = item.email;
        if (item.tel) contactInfo.tel = item.tel;
        const schedules = item.schedules.map((schedule) => {
          const from = schedule.from.$d || schedule.from;
          const to = schedule.to.$d || schedule.to;
          let scheduleItem = {
            from: moment(dayjs(from).tz(dayjs.tz.guess()).$d).format('YYYY-MM-DD HH:mm:ss'),
            to: moment(dayjs(to).tz(dayjs.tz.guess()).$d).format('YYYY-MM-DD HH:mm:ss'),
            week_days: schedule.weekDays.map((day) => day.name),
          };
          if (schedule.id) {
            scheduleItem.id = schedule.id;
          }
          return scheduleItem;
        });
        return {
          address_location_id: item.address_location_id,
          delivery_pickup_price: +item.delivery_pickup_price || null,
          currency_id: item.currency_id || null,
          pickup_name: item.name,
          district: item.district || null,
          deadline_days: +item.deadline_days || null,
          availability: +item.availability || null,
          info: item.info || null,
          contact_info: Object.keys(contactInfo) ? contactInfo : null,
          schedules: schedules,
        };
      });
    }
    const handAddressData = [];
    if (data.delivery.delivery_hand) {
      let handAddresses = {};
      if (data.handAddresses) {
        handAddresses = {
          address_location_id: data.handAddresses.address_location_id || '',
          distance: parseFloat(data.handAddresses.distance) || parseFloat('0'),
          distance_unit_id: data.handAddresses.distance_unit_id || unitsList[3].id,
        };
        if (data.handAddresses) {
          handAddresses.id = data.handAddresses.id;
        }
      }
      if (Object.keys(handAddresses).length) {
        handAddressData.push(handAddresses);
      }
    }
    const res = await editCompanyDeliveryMethods({
      variables: {
        input: {
          company_id: companyId,
          deliveryMethods: data.delivery,
          pickupAddresses: pickupData,
          handAddresses: handAddressData,
        },
      },
    });
    if (res.data && res.data.editCompanyDeliveryMethods) {
      setPickupsList([]);
      setDeliveryMethodData(res.data.editCompanyDeliveryMethods);
      let pickupData = res.data.editCompanyDeliveryMethods.shopAddresses.filter(
        (item) => item.is_pickup
      );
      setPickupsList(pickupData);

      setActiveStep((step) => step + 1);
    }
  };

  const handleSaveInvestForm = async (data) => {
    try {
      await editCompany({
        variables: {
          input: {
            id: companyId,
            is_open_for_investment: data.openForInvestment,
            is_draft: isDraft,
          },
        },
      });
      setOpenSuccessAlert(true);
      navigate(
        `/dashboard/company/${companyId}/edit?fromActiveTab=my_company&fromActiveChildTab=active`,
        {
          replace: true,
        }
      );
    } catch (e) {
      console.error(e);
      setOpenErrorAlert(true);
    }
  };

  const handleSavePlatformForm = async (data) => {
    try {
      if (data.goals && data.goals.length > 0) {
        setIsErrorFill(false);
        let goals = data.goals
          .filter((goal) => goal.checked)
          .map((goal) => ({
            name: goal.name,
            description: goal.description,
          }));
        await editCompanyGoals({
          variables: {
            input: {
              company_id: companyId,
              goals: goals,
            },
          },
        });
      } else {
        setIsErrorFill(true);
      }
    } catch (e) {
      console.error(e);
      setOpenErrorAlert(true);
    }
    setActiveStep((step) => step + 1);
  };

  const openNewPickupModal = () => {
    setIsNewPickupModalOpen(true);
  };

  const pickupEdit = (data) => {
    pickupUpdate(editablePickupIndex, data);
  };

  const onSubmit = (data) => {
    switch (activeStep) {
      case 0:
        return handleSaveBusinessForm(data);
      case 1:
        return handleSaveManagerForm(data);
      case 2:
        return handleSaveSocialForm(data);
      case 3:
        return handleSaveDocumentsForm(data);
      case 4:
        return handleSavePaymentForm(data);
      // case 5:
      //   return handleSaveDeliveryForm(data);
      case 5:
        return handleSavePlatformForm(data);
      case 6:
        return handleSaveInvestForm(data);
      default:
        return false;
    }
  };

  const renderStepContent = (step) => {
    switch (step) {
      case 0:
        return (
          <BusinessForm
            setFile={setFile}
            file={file}
            companyInfo={companyData}
            showGreen={true}
            openForDonation={true}
          />
        );
      case 1:
        return <ManagerForm user={user} />;
      case 2:
        return (
          <SocialForm
            contactsData={contactsData}
            setContactsData={setContactsData}
            socialsData={socialsData}
            setSocialsData={setSocialsData}
          />
        );
      case 3:
        return (
          <DocumentsUploadForm
            serviceData={serviceData}
            categories={categories}
            setCategories={setCategories}
          />
        );
      case 4:
        return (
          <PaymentForm
            companyId={companyData?.id}
            basePage={'/dashboard/companyProfile/representative'}
          />
        );
      // case 5:
      //   return (
      //     <DeliveryForm
      //       selectedCurrency={selectedCurrency}
      //       currenciesList={currenciesList}
      //       unitsList={unitsList}
      //       deliveryMethodData={deliveryMethodData}
      //       setDeliveryMethodData={setDeliveryMethodData}
      //       pickupsList={pickupsList}
      //       openNewPickupModal={openNewPickupModal}
      //       setEditPickupData={setEditPickupData}
      //       setEditablePickupIndex={setEditablePickupIndex}
      //       setIsEditModal={setIsEditModal}
      //       pickupData={pickupData}
      //       setPickupData={setPickupData}
      //     />
      //   );
      case 5:
        return (
          <PlatformForm
            save={() => {
              methods.handleSubmit(onSubmit);
              setSubStepOpen(false);
            }}
            goals={goalsList}
            setSubStepOpen={setSubStepOpen}
            isErrorFill={isErrorFill}
            setIsErrorFill={setIsErrorFill}
          />
        );
      case 6:
        return <InvestForm companyData={companyData} />;
      default:
        return <Box />;
    }
  };

  return (
    <>
      {!loading ? (
        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(onSubmit)} style={{ width: '100%' }}>
            {!searchParamsCompanyId && (
              <>
                <Box className={styles.createText}>
                  <h3 className={styles.title}> Create Your Company Account</h3>
                  <h4 className={styles.subTitle}>Company representative</h4>
                </Box>
              </>
            )}
            <Box style={{ marginBottom: 20 }}>
              <FormStepper activeStep={activeStep} steps={FORM_STEPS} />
            </Box>
            <Box className={styles.form}>{renderStepContent(activeStep)}</Box>
            {!subStepOpen && !searchParamsCompanyId && (
              <Box className={styles.buttons}>
                <button
                  className={styles.buttonCancel}
                  type="button"
                  onClick={() => {
                    if (activeStep === 0) {
                      navigate(`/dashboard/companyProfile`);
                    } else {
                      setActiveStep((step) => step - 1);
                    }
                  }}
                >
                  BACK
                </button>
                {activeStep !== lastStepNumber ? (
                  <button className={styles.buttonNext} type="submit">
                    {activeStep === 6 ? 'proceed' : 'Save and proceed'}
                  </button>
                ) : (
                  <Box className={styles.lastStepButtons}>
                    <button
                      className={styles.buttonDraft}
                      onClick={() => {
                        setIsDraft(true);
                      }}
                      type="submit"
                    >
                      {'Save to draft'}
                    </button>
                    <button
                      className={styles.buttonNextLast}
                      onClick={() => {
                        setIsDraft(false);
                      }}
                      type="submit"
                    >
                      {'Create your company account'}
                    </button>
                    <button
                      className={styles.buttonNextLastMobile}
                      onClick={() => {
                        setIsDraft(false);
                      }}
                      type="submit"
                    >
                      {'Create'}
                    </button>
                  </Box>
                )}
              </Box>
            )}
            {!subStepOpen && searchParamsCompanyId && (
              <Box className={styles.buttons}>
                <Box>
                  {activeStep > 0 ? (
                    <button
                      className={styles.buttonCancel}
                      type="button"
                      onClick={() => {
                        setActiveStep((step) => step - 1);
                      }}
                    >
                      BACK
                    </button>
                  ) : null}
                </Box>
                <Box>
                  {activeStep !== lastStepNumber ? (
                    <button
                      className={styles.buttonNext}
                      type="submit"
                      style={{ marginRight: '10px' }}
                    >
                      {'SAVE AND PROCEED'}
                    </button>
                  ) : (
                    <Box className={styles.lastStepButtons}>
                      <button
                        className={styles.buttonDraft}
                        onClick={() => {
                          setIsDraft(true);
                        }}
                        type="submit"
                      >
                        {'Save to draft'}
                      </button>
                      <button
                        className={styles.buttonNext}
                        onClick={() => {
                          setIsDraft(false);
                        }}
                        type="submit"
                        style={{ marginRight: '10px' }}
                      >
                        {'SAVE'}
                      </button>
                    </Box>
                  )}
                </Box>
              </Box>
            )}
          </form>
        </FormProvider>
      ) : (
        <PartialLoader loading={loading} isLightContainer />
      )}
      <Snackbar
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        open={openErrorAlert}
        onClose={() => setOpenErrorAlert(false)}
      >
        <Alert
          onClose={() => setOpenErrorAlert(false)}
          severity="error"
          sx={{ width: '100%', backgroundColor: '#D32F2F', color: '#ffffff' }}
        >
          Something went wrong, try again
        </Alert>
      </Snackbar>
      <Snackbar
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        open={openSuccessAlert}
        onClose={() => setOpenSuccessAlert(false)}
      >
        <Alert
          onClose={() => setOpenSuccessAlert(false)}
          severity="success"
          sx={{ width: '100%', backgroundColor: '#118c12', color: '#ffffff' }}
        >
          Company Saved Successfully
        </Alert>
      </Snackbar>
      {isNewPickupModalOpen && (
        <FormProvider {...pickupMethods}>
          <AddNewPickupModal
            editData={editPickupData}
            currenciesList={currenciesList}
            open={isNewPickupModalOpen}
            pickupAppend={pickupAppend}
            pickupEdit={pickupEdit}
            onClose={() => setIsNewPickupModalOpen(false)}
            methods={pickupMethods}
            setIsEditModal={setIsEditModal}
            isEditModal={isEditModal}
            selectedCurrency={selectedCurrency}
            setSelectedCurrency={setSelectedCurrency}
          />
        </FormProvider>
      )}
    </>
  );
};

export default CompanyProfilePage;
