import React, { useEffect, useState, useRef } from 'react';
import styles from './SearchProduct.module.scss';
import { Box, InputBase, ClickAwayListener } from '@mui/material';
import { CloseIcon, ArrowAscIcon } from 'tt-ui-kit';
import Image from '../Image';
import { Loader } from 'tt-ui-kit';
import { useNavigate } from 'react-router';
import { useLazyQuery } from '@apollo/client';
import { GET_SHOP_PRODUCTS, GET_CARBON_PRODUCTS, GET_SHOP_SERVICES } from '../../api';

const SearchProduct = ({ user }) => {
  const navigate = useNavigate();
  const [productData, setProductData] = useState();
  const [carbonData, setCarbonData] = useState();
  const [serviceData, setServiceData] = useState();
  const [filtredProduct, setFiltredProduct] = useState([]);
  const [filtredService, setFiltredService] = useState([]);
  const [filtredCarbon, setFiltredCarbon] = useState([]);
  const [valueSearch, setValueSearch] = useState('');
  const [isOverlayVisible, setOverlayVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [getShopServices] = useLazyQuery(GET_SHOP_SERVICES);
  const [getShopProducts] = useLazyQuery(GET_SHOP_PRODUCTS);
  const [getCarbonProducts] = useLazyQuery(GET_CARBON_PRODUCTS);
  const searchComponentRef = useRef(null);
  const searchComponentRef2 = useRef(null);

  const getProduct = async (e) => {
    const resProduct = await getShopProducts({
      variables: {
        input: { partial_search: e },
        first: 15,
        page: 1,
      },
      fetchPolicy: 'network-only',
    });
    if (resProduct.data) {
      setProductData(resProduct.data.getShopProductsByWithPagination.data);
    }
  };

  const getService = async (e) => {
    const resServices = await getShopServices({
      variables: {
        input: { partial_search: e },
        first: 15,
        page: 1,
      },
      fetchPolicy: 'network-only',
    });
    if (resServices.data) {
      setServiceData(resServices.data.getShopServices.data);
    }
  };

  const getCarbon = async (e) => {
    const resCarbon = await getCarbonProducts({
      variables: {
        input: { partial_name: e },
        first: 15,
        page: 1,
      },
      fetchPolicy: 'network-only',
    });
    if (resCarbon.data) {
      setCarbonData(resCarbon.data.getCarbonCreditsByWithPagination.data);
    }
  };

  useEffect(() => {
    if (productData?.length > 0 || serviceData?.length > 0 || carbonData?.length > 0) {
      let result = distributeResults([
        { category: 'Products', items: productData },
        { category: 'Services', items: serviceData },
        { category: 'TT Carbon Registry', items: carbonData },
      ]);
      const products = result.filter((item) => item.category === 'Products');
      const services = result.filter((item) => item.category === 'Services');
      const carbon = result.filter((item) => item.category === 'TT Carbon Registry');
      setFiltredProduct(products);
      setFiltredService(services);
      setFiltredCarbon(carbon);
    } else {
      setFiltredProduct([]);
      setFiltredService([]);
      setFiltredCarbon([]);
    }
  }, [carbonData, productData, serviceData]);

  useEffect(() => {
    if (filtredProduct || filtredCarbon || filtredService) {
      setLoading(false);
    }
  }, [filtredProduct, filtredCarbon, filtredService]);

  const onClick = async (e) => {
    if (e.length > 1 && valueSearch) {
      setLoading(true);
      setOverlayVisible(true);
      await getProduct(e);
      await getService(e);
      await getCarbon(e);
    } else if (e.length === 1) {
      setOverlayVisible(true);
      setProductData([]);
      setCarbonData([]);
      setServiceData([]);
    } else {
      setOverlayVisible(false);
      setProductData([]);
      setCarbonData([]);
      setServiceData([]);
    }
  };

  const distributeResults = (categories) => {
    const maxItemsPerCategory = 3;
    let totalItems = 9;
    let result = [];

    categories.forEach((category) => {
      if (category.items.length > 0) {
        const itemsToShow = Math.min(category.items.length, maxItemsPerCategory);
        result.push({
          category: category.category,
          items: category.items.slice(0, itemsToShow),
        });
        totalItems -= itemsToShow;
      }
    });

    if (totalItems > 0) {
      categories.forEach((category) => {
        if (totalItems > 0 && category.items.length > 0) {
          const currentCategory = result.find((res) => res.category === category.category);
          const additionalItemsCount = Math.max(
            totalItems,
            maxItemsPerCategory - (currentCategory?.items.length || 0)
          );
          const additionalItems = category.items.slice(
            currentCategory?.items.length || 0,
            (currentCategory?.items.length || 0) + additionalItemsCount
          );
          if (currentCategory) {
            currentCategory.items = [...currentCategory.items, ...additionalItems];
            totalItems -= additionalItems.length;
          }
        }
      });
    }
    return result;
  };

  const openProduct = (productId, type, userId) => {
    let url = '';
    switch (type) {
      case 'product':
        if (userId === user) {
          url = `/dashboard/product/${productId}/preview`;
        } else {
          url = `/dashboard/product/${productId}`;
        }
        break;
      case 'service':
        if (userId === user) {
          url = `/dashboard/service/${productId}/preview`;
        } else {
          url = `/dashboard/service/${productId}`;
        }
        break;
      case 'carbon':
        if (userId === user) {
          url = `/dashboard/credit/${productId}/preview`;
        } else {
          url = `/dashboard/credit/${productId}`;
        }
        break;
      default:
        url = '';
    }
    if (url) {
      navigate(url);
      window.location.reload();
    }
  };

  const handleClickAway = (event) => {
    if (
      (searchComponentRef.current && !searchComponentRef.current.contains(event.target)) ||
      (searchComponentRef2.current && !searchComponentRef2.current.contains(event.target))
    ) {
      setOverlayVisible(false);
      setValueSearch('');
      setFiltredProduct([]);
      setFiltredService([]);
      setFiltredCarbon([]);
    }
  };

  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <Box className={styles.searchBlock}>
        {isOverlayVisible && <Box className={styles.overlay}></Box>}
        <Box
          className={`${styles.searchFilter} ${isOverlayVisible ? styles.highlightedInput : ''}`}
          ref={searchComponentRef}
        >
          <Image src="/images/market-place/search-icon.svg" width={19.54} height={19.54} />
          <InputBase
            sx={{ ml: 1, flex: 1 }}
            placeholder="Search"
            value={valueSearch}
            onChange={(e) => {
              setValueSearch(e.target.value);
              onClick(e.target.value);
            }}
          />
          {isOverlayVisible && (
            <CloseIcon
              style={{ cursor: 'pointer' }}
              onClick={() => {
                setOverlayVisible(false);
                setValueSearch('');
                setFiltredProduct([]);
                setFiltredService([]);
                setFiltredCarbon([]);
              }}
            />
          )}
        </Box>
        {isOverlayVisible && (
          <ClickAwayListener onClickAway={handleClickAway}>
            <Box className={styles.dropdownSearch} ref={searchComponentRef2}>
              {loading ? (
                <Box className={styles.loading}>
                  <Loader />
                </Box>
              ) : filtredProduct.length === 0 &&
                filtredService.length === 0 &&
                filtredCarbon.length === 0 ? (
                <Box className={styles.loading}>
                  <p>Nothing found? Try a different query.</p>
                </Box>
              ) : (
                <>
                  {filtredProduct.length > 0 && (
                    <Box>
                      <Box className={styles.typeList}>
                        <p>Products</p>
                      </Box>
                      {filtredProduct[0].items.map((itemSearch) => (
                        <Box
                          key={itemSearch.id}
                          className={styles.searchRow}
                          onClick={() => openProduct(itemSearch.id, 'product', itemSearch.user_id)}
                        >
                          <Box className={styles.searchItem}>
                            <p className={styles.searchTitle}>{itemSearch.name}</p>
                            <p className={styles.searchSubtitle}>{itemSearch.category?.name}</p>
                          </Box>
                          <ArrowAscIcon className={styles.arrowAsIcon} />
                        </Box>
                      ))}
                      <div className={styles.line}></div>
                    </Box>
                  )}
                  {filtredService.length > 0 && (
                    <Box>
                      <Box className={styles.typeList}>
                        <p>Services</p>
                      </Box>
                      {filtredService[0].items.map((itemSearch) => (
                        <Box
                          key={itemSearch.id}
                          className={styles.searchRow}
                          onClick={() => openProduct(itemSearch.id, 'service', itemSearch.user_id)}
                        >
                          <Box className={styles.searchItem}>
                            <p className={styles.searchTitle}>{itemSearch.name}</p>
                            <p className={styles.searchSubtitle}>{itemSearch.category?.name}</p>
                          </Box>
                          <ArrowAscIcon className={styles.arrowAsIcon} />
                        </Box>
                      ))}
                      <div className={styles.line}></div>
                    </Box>
                  )}
                  {filtredCarbon.length > 0 && (
                    <Box>
                      <Box className={styles.typeList}>
                        <p>TT Carbon Registry</p>
                      </Box>
                      {filtredCarbon[0].items.map((itemSearch) => (
                        <Box
                          key={itemSearch.id}
                          className={styles.searchRow}
                          onClick={() => openProduct(itemSearch.id, 'carbon', itemSearch.user_id)}
                        >
                          <Box className={styles.searchItem}>
                            <p className={styles.searchTitle}>{itemSearch.name}</p>
                            <p className={styles.searchSubtitle}>{itemSearch.category?.name}</p>
                          </Box>
                          <ArrowAscIcon className={styles.arrowAsIcon} />
                        </Box>
                      ))}
                    </Box>
                  )}
                </>
              )}
            </Box>
          </ClickAwayListener>
        )}
      </Box>
    </ClickAwayListener>
  );
};

export default SearchProduct;
