import React, { useEffect, useState } from 'react';
import { Button, Modal, Radio } from 'tt-ui-lib/core';
import styles from './AddProposalModal.module.scss';

const proposalList = [
  // {
  //   value: 'product',
  //   title: 'Product',
  //   disabled: false,
  //   description:
  //     'Generate a product page within your inventory with a detailed description to engage your customers. Add supplementary documents that substantiate the quality and certification of your products, reinforcing their credibility.',
  // },
  // {
  //   value: 'service',
  //   title: 'Service',
  //   disabled: false,
  //   description:
  //     'Specify the scope of your service provision and fill out the following form. Set up a work schedule so your clients can book a slot for a consultation.',
  // },
  {
    value: 'credit',
    title: 'Carbon credit',
    disabled: false,
    description: "Choose this option if you're about to sell the digitized carbon credits.",
  },
];

const Index = ({ visible, onClose, onSubmit, proposalUserData }) => {
  const [proposal, setProposal] = useState('product');
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setProposal('product');
    setTimeout(() => setLoading(false), 4000);
  }, [visible]);

  return (
    <Modal
      open={visible}
      onClose={() => onClose()}
      onClick={(e) => e.stopPropagation()}
      title="Select an option that fits your proposal best:"
      className={styles.modalWrapper}
    >
      <div className={styles.modalContainer}>
        <Radio
          className={styles.radioContainer}
          value={proposal}
          onChange={(e) => {
            setProposal(e.target.value);
          }}
          options={proposalList.map((item, i) => ({
            key: item.value,
            label:
              item.value === 'credit' &&
              proposalUserData?.user?.roles_list &&
              !JSON.parse(proposalUserData?.user?.roles_list).includes('transparenterra') ? null : (
                <div className={styles.label}>
                  <div
                    className={`${styles.labelTitle} ${
                      proposal === item.value ? styles.activeLabelTitle : ''
                    }`}
                  >
                    {item.title}
                  </div>
                  <div className={styles.labelDescription}>{item.description}</div>
                </div>
              ),
            value: item.value,
          }))}
        />
        {/* <RadioGroup
          column
          aria-labelledby="demo-row-radio-buttons-group-label"
          name="row-radio-buttons-group"
          onChange={(e) => setProposal(e.target.value)}
          defaultValue={proposal}
        >
          <PartialLoader loading={loading} isLightContainer />
          {proposalList.map((item) =>
            (item.value === 'credit' &&
              proposalUserData?.user?.roles_list &&
              JSON.parse(proposalUserData?.user?.roles_list).includes('transparenterra')) ||
            item.value !== 'credit' ? (
              <Radio
                key={item.value}
                value={item.value}
                disabled={item.disabled}
                sx={{
                  '&': {
                    color: '#212121',
                    padding: 0,
                  },
                  '&.Mui-checked': {
                    color: '#143471',
                  },
                }}
                label={
                  <div className={styles.label}>
                    <div
                      className={`${styles.labelTitle} ${
                        proposal === item.value ? styles.activeLabelTitle : ''
                      }`}
                    >
                      {item.title}
                    </div>
                    <div className={styles.labelDescription}>{item.description}</div>
                  </div>
                }
              />
            ) : null
          )}
        </RadioGroup> */}

        <div className={styles.buttonGroup}>
          <Button type="default" onClick={() => onClose()}>
            Cancel
          </Button>
          <Button type="primary" onClick={() => onSubmit(proposal)}>
            Create
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default Index;
