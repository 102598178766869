import React, { useEffect, useState } from 'react';
import { IconButton, Typography, Box } from '@mui/material';
import StarIcon from '@mui/icons-material/Star';
import styles from './ProductCardListMode.module.scss';
import ChatBubbleOutlineIcon from '@mui/icons-material/ChatBubbleOutline';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import FavoriteIcon from '@mui/icons-material/Favorite';
import ItemCounter from '../ItemCounter';
import { useMutation } from '@apollo/client';
import { CREATE_AND_UPDATE_USER_CART } from '../../../../api';
import { Button, Loader, openNotification } from 'tt-ui-lib/core';
import ModeEditIcon from '@mui/icons-material/ModeEdit';

export const ProductCardListMode = (props) => {
  const {
    pressCard,
    existData,
    onUpdate,
    isSeller,
    editProduct,
    isCredit,
    handleLoginClick,
    user,
    pageType,
    middlePressCard,
    productType = 'product',
    viewBtnText = 'View',
    buyBtnText = 'BUY',
    onSilenceUpdate,
  } = props;
  const isInCart =
    (existData?.productPrices?.length && existData.productPrices[0].userCart?.quantity > 0) ||
    false;

  const [clickedFavorite, setClickedFavorite] = useState(false);

  const [lastVisiblePrice, setLastVisiblePrice] = useState({});
  const [addItemToCartRequest] = useMutation(CREATE_AND_UPDATE_USER_CART);
  const [loadingBuy, setLoadingBuy] = useState(false);

  const addItemToCart = () => {
    (async () => {
      setLoadingBuy(true);
      const input = {
        product_id: existData.id,
        quantity: existData.lastVisiblePrice?.min_order_quantity || 1,
        product_price_id: existData.lastVisiblePrice.id,
      };

      const req = await addItemToCartRequest({
        variables: {
          input: input,
        },
      });

      if (req?.data?.addToUserCart) {
        openNotification({
          type: 'success',
          message: 'Product added to your cart',
        });
        onSilenceUpdate && onSilenceUpdate();
      } else {
        openNotification({
          type: 'error',
          message: 'Error while adding product to your cart, try again later',
        });
      }
      setLoadingBuy(false);
    })();
  };

  useEffect(() => {
    if (existData?.lastVisiblePrice?.id) {
      setLastVisiblePrice(existData.lastVisiblePrice);
    }
  }, [existData]);

  const favoriteClick = (e) => {
    setClickedFavorite(!clickedFavorite);
    e.stopPropagation();
  };

  const onPressCard = (event) => {
    if (event.button === 1) {
      middlePressCard();
    } else if (event.button === 0) {
      pressCard();
    } else {
      return;
    }
  };

  const getPrice = () => {
    if (productType === 'carbon') {
      return `${existData?.currency?.symbol || '$'} ${existData?.price || 0}`;
    }
    if (productType === 'service') {
      if (existData?.price_type === 'fixed') {
        return `${existData?.currency?.symbol || '$'} ${existData?.basic_price || 0}`;
      } else {
        return `${existData?.currency?.symbol || '$'} ${existData?.hourly_rate || 0}/h`;
      }
    }
    if (productType === 'product') {
      return `${lastVisiblePrice.currency_symbol || '$'} ${lastVisiblePrice.price || 0}`;
    }
  };

  return (
    <Box className={styles.cardContainerStyle} onMouseDown={onPressCard}>
      <Box
        className={`${styles.imageContainer} ${productType === 'service' ? styles.serviceImgContainer : ''}`}
      >
        <img
          className={`${styles.cardImage} ${productType === 'service' ? styles.serviceCardImage : ''}`}
          src={
            !isCredit && existData.logos && existData.logos[0]
              ? existData.logos[0].path
              : isCredit && existData.projectType && existData.projectType.logoPath
                ? existData.projectType.logoPath
                : '/images/market-place/no_image.png'
          }
          alt=""
        />
      </Box>
      <Box className={styles.cardContainer}>
        <Box className={styles.cardBody}>
          <Box className={styles.text}>
            <Typography className={styles.productName}>{existData.name}</Typography>
            <Typography className={styles.companyName}>
              {existData?.company && (
                <a
                  href={`/dashboard/company/${existData?.company?.id}/store`}
                  target="_blank"
                  rel="noreferrer"
                  onClick={(e) => e.stopPropagation()}
                  style={{ color: 'inherit' }}
                >
                  {existData?.company?.company_name || ''}
                </a>
              )}
            </Typography>
          </Box>
          <Box className={styles.productRatingBar}>
            {isCredit ? (
              <div className={styles.availableBlock}>
                Available:{' '}
                {existData.amount ? (
                  <span>
                    {existData.amount} {existData?.unit?.name}
                  </span>
                ) : (
                  <span style={{ color: '#d32f2f' }}>Sold out</span>
                )}
              </div>
            ) : (
              <>
                <Box className={styles.ratingBarItems}>
                  <IconButton sx={{ padding: '0px', color: '#F9CB00' }}>
                    <StarIcon />
                  </IconButton>
                  <Typography sx={{ fontSize: '14px', fontWeight: 400, color: '#424242' }}>
                    0
                  </Typography>
                </Box>
                <Box className={styles.ratingBarItems}>
                  <IconButton sx={{ padding: '0px', color: '#757575' }}>
                    <ChatBubbleOutlineIcon />
                  </IconButton>
                  <Typography sx={{ fontSize: '14px', fontWeight: 400, color: '#424242' }}>
                    0
                  </Typography>
                </Box>
              </>
            )}
          </Box>
          <Typography
            sx={
              lastVisiblePrice.availability === 'available' ||
              lastVisiblePrice.availability === 'always available'
                ? { color: '#2D8342' }
                : { color: '#C4C4C4' }
            }
            className={styles.availability}
          >
            {lastVisiblePrice.availability?.replaceAll('_', ' ')}
          </Typography>
          <Box>
            {productType === 'service' && (
              <>
                <Typography className={styles.inProgressText}>2 in progress</Typography>
                <Typography className={styles.inProgressText}>from</Typography>
              </>
            )}
            {lastVisiblePrice.discount_price ||
            existData?.discount_price ||
            existData.hourly_discount_price ? (
              <>
                <Typography className={styles.oldPrice}>
                  {productType === 'service'
                    ? getPrice()
                    : `${lastVisiblePrice.currency_symbol || '$'} ${lastVisiblePrice.price || 0}`}
                </Typography>
                <Typography className={styles.discountPrice}>
                  {productType === 'service'
                    ? `${existData.currency_symbol || '$'} ${existData.discount_price || existData.hourly_discount_price || '-'}`
                    : `${lastVisiblePrice.currency_symbol || '$'} ${lastVisiblePrice.discount_price || '-'}`}
                </Typography>
              </>
            ) : (
              <Typography className={styles.price}>{getPrice()}</Typography>
            )}
          </Box>
        </Box>
        <Box className={styles.rightBlock}>
          {user.id && isSeller ? (
            <>
              <IconButton className={styles.btnEdit} onMouseDown={(e) => editProduct(e)}>
                <ModeEditIcon fontSize="small" />
              </IconButton>
              <Button type="default" className={styles.btn}>
                {viewBtnText}
              </Button>
            </>
          ) : isInCart ? (
            <ItemCounter
              id={existData.id}
              priceId={existData.lastVisiblePrice.id}
              initialCount={existData?.productPrices[0].userCart?.quantity || 0}
              price={existData.lastVisiblePrice}
              onUpdate={onUpdate}
            />
          ) : loadingBuy ? (
            <Box className={styles.loaderBox}>
              <Loader />
            </Box>
          ) : (
            <>
              <IconButton className={styles.btnFavorite} onMouseDown={(e) => favoriteClick(e)}>
                {clickedFavorite ? (
                  <FavoriteIcon style={{ color: 'red' }} />
                ) : (
                  <FavoriteBorderIcon />
                )}
              </IconButton>
              <Button
                type="default"
                disabled={
                  productType === 'service'
                    ? false
                    : isCredit
                      ? !existData.amount > 0
                      : !lastVisiblePrice.availableToBuy
                }
                className={styles.btn}
                onMouseDown={
                  !user?.id
                    ? (e) => handleLoginClick(e)
                    : (e) => {
                        if (isCredit) return;

                        e.stopPropagation();
                        productType === 'service' ? pressCard() : addItemToCart();
                      }
                }
              >
                {buyBtnText}
              </Button>
            </>
          )}
        </Box>
      </Box>
    </Box>
  );
};
