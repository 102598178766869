import React, { useContext, useEffect, useState } from 'react';
import styles from './CartPage.module.scss';
import userContext from '../../../context/User/userContext';
import { Button, Loader, LoaderOn, openNotification } from 'tt-ui-lib/core';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import Image from '../../Image';
import ErrorPaymentModal from './ErrorPaymentModal';
import { useLazyQuery, useMutation } from '@apollo/client';
import {
  CHECK_CERT_RESERVATION_STATUS,
  CHECKOUT_ORDER,
  GET_CUSTOMER_ACCOUNT_PAYMENT_METHODS,
  GET_ORDER_BT_ID,
} from '../../../api';
import PartialLoader from '../../layout/PartialLoader';
import { useDigitalAssets } from 'modules/tt-digital-assets-provider';
import { Box, Typography } from '@mui/material';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { AddIcon } from 'tt-ui-kit';
import AddNewCardModal from '../CartPage/Payment/AddNewCardModal';

const CarbonCartPage = () => {
  const { user } = useContext(userContext);
  const { id } = useParams();
  const { status } = useDigitalAssets();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const [cartData, setCartData] = useState(null);
  const [isFetchCartData, setIsFetchCartData] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  const [checkoutLoading, setCheckoutLoading] = useState(false);
  const [statusLoading, setStatusLoading] = useState(false);
  const [reservationStatus, setReservationStatus] = useState('pending');
  const [isSecondLoading, setIsSecondLoading] = useState(false);
  const [showErrorPaymentModal, setShowErrorPaymentModal] = useState(false);

  const [checkoutOrder] = useMutation(CHECKOUT_ORDER);
  const [getOrderById] = useLazyQuery(GET_ORDER_BT_ID);
  const [checkReservation] = useLazyQuery(CHECK_CERT_RESERVATION_STATUS);
  const [openAddNewCardModal, setOpenAddNewCardModal] = useState(false);
  const [selectedCard, setSelectedCard] = useState('');
  const [cardsList, setCardsList] = useState([]);
  const [getCustomerAccountPaymentMethods] = useLazyQuery(GET_CUSTOMER_ACCOUNT_PAYMENT_METHODS, {
    fetchPolicy: 'network-only',
  });

  const fetchCardsListData = async () => {
    const res = await getCustomerAccountPaymentMethods({});
    if (res.data && res.data.getCustomerAccountPaymentMethods) {
      setCardsList(res.data.getCustomerAccountPaymentMethods);
    }
  };

  useEffect(() => {
    (async () => {
      setIsLoading(true);
      if (id) {
        await fetchCardsListData();
      }
      setIsLoading(false);
    })();
  }, []);

  useEffect(() => {
    if (cardsList.length > 0) {
      const defaultCard = cardsList.find((card) => card.is_default);
      if (defaultCard && defaultCard.id) {
        setSelectedCard(defaultCard.id);
      } else {
        setSelectedCard(cardsList[0].id);
      }
    }
  }, [cardsList]);

  const checkReservationStatus = (ord_id) => {
    setStatusLoading(true);

    checkReservation({
      variables: {
        order_id: ord_id,
      },
      fetchPolicy: 'network-only',
    })
      .then((response) => {
        if (response && response?.data && response?.data?.checkReservationStatus) {
          setReservationStatus(response?.data?.checkReservationStatus?.status || 'pending');
        } else {
          openNotification({
            type: 'error',
            message: 'Error while fetching reservation data, please try order credit again',
            duration: 6,
          });
          navigate(`/dashboard/credit/${id}`);
        }
      })
      .catch(() => {
        openNotification({
          type: 'error',
          message: 'Error while fetching reservation data, please try order credit again',
          duration: 6,
        });
        navigate(`/dashboard/credit/${id}`);
      });
  };

  // Запрос на получение данных о корзине (цена, скидка, итог)
  const fetchCartData = (initial = true) => {
    initial ? setIsLoading(true) : setIsSecondLoading(true);
    const orderId = searchParams.get('orderId');

    getOrderById({
      variables: {
        id: orderId,
      },
      fetchPolicy: 'network-only',
    })
      .then((response) => {
        if (response && response.data && response.data.getOrderById) {
          setCartData(response.data.getOrderById);
          const orderId = searchParams.get('orderId');
          checkReservationStatus(orderId);
        } else {
          openNotification({
            type: 'error',
            message: 'Error while fetching cart data, please try order credit again',
            duration: 6,
          });
          navigate(`/dashboard/credit/${id}`);
        }
      })
      .catch(() => {
        openNotification({
          type: 'error',
          message: 'Error while fetching cart data, please try order credit again',
          duration: 6,
        });
        navigate(`/dashboard/credit/${id}`);
      })
      .finally(() => {
        initial ? setIsLoading(false) : setIsSecondLoading(false);
      });
  };

  // Запрос на создание оплаты
  const proceedCheckout = () => {
    const orderId = searchParams.get('orderId');

    if (!searchParams.get('orderId') || !id) {
      return;
    }

    setCheckoutLoading(true);

    const payload = {
      id: orderId,
      card_id: selectedCard,
      payment_system: 'stripe',
    };

    checkoutOrder({
      variables: {
        input: payload,
      },
    })
      .then((checkoutOrderData) => {
        if (
          checkoutOrderData &&
          checkoutOrderData.data &&
          checkoutOrderData.data.checkoutOrder &&
          checkoutOrderData.data.checkoutOrder.payment_status &&
          checkoutOrderData.data.checkoutOrder.payment_status === 'succeeded'
        ) {
          window.open(
            status === 'notConnected'
              ? `${process.env.REACT_APP_TT}/dashboard/decarbonizator`
              : `${process.env.REACT_APP_DIGITAL_ASSETS}/dashboard/digital_assets?activeTab=tokens`,
            '_self'
          );
        } else {
          // Вызов модального окна с сообщением о недоступности оплаты
          setShowErrorPaymentModal(true);
          setCheckoutLoading(false);
        }
      })
      .catch(() => {
        // Вызов модального окна с сообщением о недоступности оплаты
        setShowErrorPaymentModal(true);
        setCheckoutLoading(false);
      });
  };

  useEffect(() => {
    if (isFetchCartData && user.id && id) {
      fetchCartData();
      setIsFetchCartData(false);
    }
  }, [isFetchCartData, user]);

  useEffect(() => {
    if (cartData === undefined || cartData === null) return;

    const checkStatusData = () => {
      if (reservationStatus === 'bc_added') {
        clearTimeout();
        setStatusLoading(false);
      } else if (reservationStatus === 'cancelled') {
        clearTimeout();
        openNotification({
          type: 'error',
          message: 'Error while reservation credit, try again later', // TODO
          duration: 6,
        });
        navigate(`/dashboard/credit/${id}`);
      } else {
        setStatusLoading(true);
        const orderId = searchParams.get('orderId');

        if (!orderId || !id) {
          openNotification({
            type: 'error',
            message: 'Error while reservation credit, try again later', // TODO
            duration: 6,
          });
          navigate(`/dashboard/credit/${id}`);
          return;
        }
        checkReservationStatus(orderId);

        setTimeout(() => checkStatusData(), 10000);
      }
    };

    if (reservationStatus === 'bc_added') {
      clearTimeout();
      setStatusLoading(false);
    } else if (reservationStatus === 'cancelled') {
      clearTimeout();
      openNotification({
        type: 'error',
        message: 'Error while reservation credit, try again later', // TODO
        duration: 6,
      });
      navigate(`/dashboard/credit/${id}`);
    } else {
      checkStatusData();
    }

    return () => clearTimeout();
  }, [reservationStatus]);

  return (
    <div className={styles.middleContent}>
      {isLoading ? (
        <div className={styles.loadingContainer}>
          <Loader />
        </div>
      ) : (
        <div className={styles.cartListContainer}>
          <div className={styles.containerPayment}>
            <div className={styles.paymentTypes}>
              <div className={styles.paymentType}>
                <Image src={'/images/payment/stripe.svg'} width={24} height={24} />
                <p className={styles.name}>stripe</p>
              </div>
              <div className={`${styles.paymentType} ${styles.paymentTypeDisabled}`}>
                <Image src={'/images/payment/pay-pal.svg'} width={24} height={24} />
                <p className={styles.name}>PayPal</p>
              </div>
              <div className={`${styles.paymentType} ${styles.paymentTypeDisabled}`}>
                <Image src={'/images/payment/crypto.svg'} width={24} height={24} />
                <p className={styles.name}>Crypto</p>
              </div>
            </div>

            <p className={styles.description}>
              You can pay for your purchases on TT using Stripe, a multi-functional financial
              system. Stripe is a payment processing platform that lets you safely and effectively
              accept online and credit card payments. Stripe is a certified PCI Service Provider
              Level 1. This is the most stringent level of certification available in the payments
              industry.
              <br /> <br />
              Currently, you are connecting your payment card to Stripe. The order amount will be
              specified and charged once the seller confirms the order.
            </p>
            {cardsList.length > 0 ? (
              <Box className={styles.cardsBlock}>
                <Select
                  defaultValue={false}
                  className={styles.select}
                  onChange={(e) => setSelectedCard(e.target.value)}
                  value={selectedCard}
                  style={{ display: 'flex' }}
                  sx={{
                    '.MuiOutlinedInput-notchedOutline': {
                      border: '0 !important',
                    },
                  }}
                  renderValue={(value) =>
                    value ? (
                      <Box style={{ display: 'flex' }}>
                        <Typography className={styles.periodValue}>
                          {cardsList.find((item) => item.id === value) &&
                            `**** **** **** ${cardsList.find((item) => item.id === value).last4}`}
                        </Typography>
                      </Box>
                    ) : (
                      <Typography className={styles.periodValue}>Select card to pay</Typography>
                    )
                  }
                >
                  {cardsList.map((item, index) => (
                    <MenuItem key={index} value={item.id}>
                      <Typography className={styles.dateItems}>
                        **** **** **** {item.last4}
                      </Typography>
                    </MenuItem>
                  ))}
                </Select>
                <Button
                  type="link"
                  className={styles.addCardBtn}
                  onClick={() => setOpenAddNewCardModal(true)}
                >
                  <AddIcon style={{ width: 24, height: 24 }} />
                  Add new card
                </Button>
              </Box>
            ) : (
              <Button
                type="primary"
                className={styles.addCardBtn}
                onClick={() => setOpenAddNewCardModal(true)}
              >
                <AddIcon style={{ width: 24, height: 24 }} />
                Add new card
              </Button>
            )}
            {setOpenAddNewCardModal && (
              <AddNewCardModal
                open={openAddNewCardModal}
                setOpen={setOpenAddNewCardModal}
                setCardsList={setCardsList}
              />
            )}
          </div>

          <div className={styles.containerCheckout}>
            {isLoading && <LoaderOn wrapperClassName={styles.loader} />}
            {cartData ? (
              <div className={styles.checkout}>
                <div className={styles.yourCart}>
                  <p className={styles.title}>Your cart</p>
                  <div className={styles.cartTotalBlock}>
                    <div className={styles.subtotal}>
                      <p className={styles.title}>Subtotal</p>
                      <p className={styles.price}>
                        {cartData.currency.symbol}{' '}
                        {parseFloat(
                          cartData.amount / (1 - cartData.fcem_discount / 100) || 0
                        ).toFixed(1)}
                      </p>
                    </div>
                    <div className={styles.discount}>
                      <p className={styles.title}>Discount</p>
                      <p className={styles.discountPrice}>
                        - {cartData.currency.symbol}{' '}
                        {parseFloat(
                          (cartData.amount + (cartData.fcem_discount / 100) * cartData.amount ||
                            0) - cartData.amount
                        ).toFixed(1)}
                      </p>
                    </div>
                  </div>
                </div>
                <div className={styles.totalPrice}>
                  <p className={styles.title}>Total price</p>
                  <p className={styles.price}>
                    {cartData.currency.symbol} {cartData.amount}
                  </p>
                </div>
                {/*<p className={styles.description}>*/}
                {/*  Available delivery methods and times can be selected at checkout*/}
                {/*</p>*/}
              </div>
            ) : (
              <p className={styles.emptyMessage}>
                No data for payment processing, try recreate order
              </p>
            )}
            <Button
              type="primary"
              onClick={() => proceedCheckout()}
              disabled={!cartData || cartData?.status === 'rejected'}
              className={`${styles.button}`}
              loading={checkoutLoading || isSecondLoading}
            >
              Continue
            </Button>
          </div>
        </div>
      )}
      {/* Модалка для отображения недоступности оплаты */}
      <ErrorPaymentModal
        open={showErrorPaymentModal}
        onClose={() => setShowErrorPaymentModal(false)}
      />

      <PartialLoader
        loading={statusLoading}
        holderText={
          <div style={{ textAlign: 'center', lineHeight: '24px' }}>
            We are currently in the process of reserving carbon credits for you. <br /> Please wait,
            this process may take a minute.
          </div>
        }
        isLightContainer
      />
    </div>
  );
};

export default CarbonCartPage;
