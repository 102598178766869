import React from 'react';
import ImageGallery from 'react-image-gallery';
import 'react-image-gallery/styles/css/image-gallery.css';

const images = [
  {
    original:
      'https://img.freepik.com/free-photo/vertical-shot-of-trees-reflecting-on-a-water_181624-44054.jpg',
    thumbnail: 'https://picsum.photos/id/1018/250/150/',
    originalHeight: 460,
    originalWidth: 426,
  },
  {
    original:
      'https://img.freepik.com/free-photo/vertical-shot-of-trees-reflecting-on-a-water_181624-44054.jpg',
    thumbnail: 'https://picsum.photos/id/1018/250/150/',
    originalHeight: 460,
    originalWidth: 426,
  },
  {
    original:
      'https://img.freepik.com/free-photo/vertical-shot-of-trees-reflecting-on-a-water_181624-44054.jpg',
    thumbnail: 'https://picsum.photos/id/1018/250/150/',
    originalHeight: 460,
    originalWidth: 426,
  },
  {
    original: 'https://picsum.photos/id/1019/1000/600/',
    thumbnail: 'https://picsum.photos/id/1019/250/150/',
  },
];

export class MyGallery extends React.Component {
  render() {
    return <ImageGallery items={images} showFullscreenButton={false} showPlayButton={false} />;
  }
}
