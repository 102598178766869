import React from 'react';
import { Modal, Tooltip } from 'tt-ui-lib/core';

const UpgradeRoleModal = ({ open, setOpen, onLinkClick, linkTooltipText }) => (
  <Modal
    title="Functionality is not available"
    open={open}
    onClose={() => {
      setOpen(false);
    }}
  >
    <div
      style={{
        fontSize: 16,
        fontWeight: 400,
        margin: '16px 0',
      }}
    >
      We&apos;re sorry. This functionality is not available for your role. <br /> Want to upgrade
      your role, follow this{' '}
      {linkTooltipText ? (
        <Tooltip title={linkTooltipText}>
          <a onClick={onLinkClick} style={{ cursor: 'pointer', textDecoration: 'underline' }}>
            link
          </a>
        </Tooltip>
      ) : (
        <a onClick={onLinkClick} style={{ cursor: 'pointer', textDecoration: 'underline' }}>
          link
        </a>
      )}
    </div>
  </Modal>
);

export default UpgradeRoleModal;
