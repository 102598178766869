import React, { useEffect, useState } from 'react';
import { gql, useQuery } from '@apollo/client';
import { GET_PAYMENT_ACCOUNT_LOGIN_LINK } from '../../../api';
import { useSearchParams } from 'react-router-dom';
import PartialLoader from '../../../components/layout/PartialLoader';
import Alert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';

//4. Create page with The Payment System redirection - Component to login to payment account
const LoginPage = () => {
  const [searchParams] = useSearchParams();
  const [companyId, setCompanyId] = useState(null);
  const [openErrorAlert, setOpenErrorAlert] = useState(false);

  useEffect(() => {
    (async () => {
      const searchParamsId = searchParams.get('id');
      if (searchParamsId) {
        setCompanyId(searchParamsId);
      }
    })();
  }, [searchParams]);

  const { data, loading, error } = useQuery(GET_PAYMENT_ACCOUNT_LOGIN_LINK, {
    variables: {
      input: {
        payment_system: `stripe`,
        company_id: `${companyId}`,
      },
    },
  });

  useEffect(() => {
    if (error) {
      setOpenErrorAlert(true);
      console.error(error);
    }
  }, [error]);

  //7. Redirect to Payment System url
  if (data?.getPaymentAccountLoginLink?.url) {
    window.location.href = data?.getPaymentAccountLoginLink?.url;
  }

  return (
    <div>
      <PartialLoader loading={loading} isLightContainer />
      <Snackbar
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        open={openErrorAlert}
        onClose={() => setOpenErrorAlert(false)}
      >
        <Alert
          onClose={() => setOpenErrorAlert(false)}
          severity="error"
          sx={{ width: '100%', backgroundColor: '#D32F2F', color: '#ffffff' }}
        >
          Something went wrong, try again
        </Alert>
      </Snackbar>
    </div>
  );
};

export default LoginPage;
