import React, { useEffect, useState } from 'react';
import { InviteWrapper } from 'components/Invite';
import { ESGWidget } from '../../modules/tt-esg-widget';
// import 'tt-esg-widget/dist/index.css';
import styles from './DashboardWidgets.module.scss';
import { useWindowSize } from 'utils/useWindowSize';
import { styled } from '@mui/material/styles';
import MuiDrawer from '@mui/material/Drawer';

import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';

import { ReactComponent as OpenIcon } from './Icons/ArrowOpen.svg';
import { ReactComponent as CloseIcon } from './Icons/ArrowClose.svg';
import { ReactComponent as ESGFeedIcon } from './Icons/ESGFeedIcon.svg';
import { ReactComponent as IviteIcon } from './Icons/InviteIcon.svg';
import { ShopCartIcon } from 'tt-ui-kit';

import { CartWrapper } from 'components/CartWrapper';
import { useLocation } from 'react-router';

import { ClickAwayListener } from '@mui/base/ClickAwayListener';

const drawerWidth = 372;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    ...(open && {
      ...openedMixin(theme),
      '& .MuiDrawer-paper': openedMixin(theme),
    }),
    ...(!open && {
      ...closedMixin(theme),
      '& .MuiDrawer-paper': closedMixin(theme),
    }),
  })
);

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const DashboardWidgets = ({
  user,
  kyc,
  showNavbar,
  fetchCartDataWidget,
  cartIsLoading,
  cartWidgetItems,
  setCartDeleteTriggered,
}) => {
  const location = useLocation();
  const [showCartWidget, setShowCartWidget] = useState(true);

  const { width } = useWindowSize();

  const [open, setOpen] = React.useState(false);

  const [cartItemCounter, setCartItemCounter] = useState(0);

  useEffect(() => {
    handleDrawerClose();
    setShowCartWidget(
      location.pathname !== '/dashboard/cart' && location.pathname !== '/dashboard/checkout'
    );
  }, [location]);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    fetchCartDataWidget();
    if (cartWidgetItems.length > 0) {
      const allProducts = cartWidgetItems.flatMap((cart) => cart.products);
      const numbOfProducts = allProducts.length;
      setCartItemCounter(numbOfProducts);
    } else {
      setCartItemCounter(0);
    }
  }, [cartWidgetItems, user.id]);

  return width > 1580 || width < 1025 ? (
    <div
      className={styles.rightColumn}
      style={{
        top: showNavbar ? 195 : 126,
      }}
    >
      {user.id && showCartWidget && (
        <div className={styles.widgetItem}>
          <CartWrapper
            fetchCartDataWidget={fetchCartDataWidget}
            cartIsLoading={cartIsLoading}
            cartWidgetItems={cartWidgetItems}
            setCartDeleteTriggered={setCartDeleteTriggered}
          />
        </div>
      )}
      {kyc === 'success' ||
      (user?.roles_list && JSON.parse(user.roles_list).includes('transparenterra')) ? (
        <div className={styles.widgetItem}>
          <InviteWrapper />
        </div>
      ) : null}
    </div>
  ) : user?.roles_list &&
    JSON.parse(user.roles_list).includes('individual') &&
    !showCartWidget ? null : (
    <ClickAwayListener onClickAway={handleDrawerClose}>
      <div
        className={styles.drawerWrapper}
        style={{
          top: showNavbar ? 195 : 125,
          visibility: !user.id ? 'hidden' : 'inherit',
        }}
      >
        <Drawer
          variant="permanent"
          open={open}
          style={{
            borderRadius: 12,
            boxShadow: open ? '0 0 20px 2px rgba(0,0,0,0.20)' : 'none',
          }}
        >
          <DrawerHeader
            style={{
              display: 'flex',
              justifyContent: open ? 'space-between' : 'center',
              flexDirection: 'row',
              alignItems: 'center',
            }}
          >
            {open ? <div style={{ marginLeft: 15, fontWeight: 600 }}>Widgets</div> : null}
            <IconButton onClick={open ? handleDrawerClose : handleDrawerOpen}>
              {open ? <CloseIcon /> : <OpenIcon />}
            </IconButton>
          </DrawerHeader>
          <Divider />
          <List
            className={styles.drawerList}
            style={{ maxHeight: showNavbar ? 'calc(90vh - 200px)' : 'calc(90vh - 130px)' }}
          >
            {user.id && showCartWidget && (
              <ListItem disablePadding sx={{ display: 'block' }}>
                <div className={styles.drawerItem}>
                  {open ? (
                    <CartWrapper
                      fetchCartDataWidget={fetchCartDataWidget}
                      cartIsLoading={cartIsLoading}
                      cartWidgetItems={cartWidgetItems}
                      setCartDeleteTriggered={setCartDeleteTriggered}
                    />
                  ) : (
                    <ListItemIcon
                      sx={{
                        minWidth: 0,
                        mr: open ? 3 : 'auto',
                        justifyContent: 'center',
                      }}
                    >
                      <div className={styles.cartIcon}>
                        {cartItemCounter > 0 ? (
                          <div className={styles.cartItemCounter}>
                            <p>{cartItemCounter < 100 ? cartItemCounter : '99+'}</p>
                          </div>
                        ) : null}

                        <ShopCartIcon style={{ width: 25, height: 25 }} />
                      </div>
                    </ListItemIcon>
                  )}
                </div>
              </ListItem>
            )}
            {kyc === 'success' ||
            (user?.roles_list && JSON.parse(user.roles_list).includes('transparenterra')) ? (
              <ListItem disablePadding sx={{ display: 'block' }}>
                <div className={styles.drawerItem}>
                  {open ? (
                    <InviteWrapper />
                  ) : (
                    <ListItemIcon
                      sx={{
                        minWidth: 0,
                        mr: open ? 3 : 'auto',
                        justifyContent: 'center',
                      }}
                    >
                      <IviteIcon />
                    </ListItemIcon>
                  )}
                </div>
              </ListItem>
            ) : null}
          </List>
        </Drawer>
      </div>
    </ClickAwayListener>
  );
};

export default DashboardWidgets;
