import { NumericFormat } from 'react-number-format';
import { TextField } from '@mui/material';

const NumberMaskInput = (props) => {
  const { inputRef, maxValue, onChange, customInput, decimal = true, ...other } = props;
  const MAX_VALUE = maxValue || 999999;

  return (
    <NumericFormat
      {...other}
      thousandSeparator={decimal}
      decimalSeparator="."
      decimalScale={decimal ? 2 : 0}
      customInput={TextField}
      fixedDecimalScale={decimal}
      isAllowed={(values) => {
        const { floatValue } = values;
        return floatValue === undefined || floatValue <= MAX_VALUE;
      }}
      allowNegative={false}
      onValueChange={(values) => {
        if (onChange) {
          onChange({
            target: {
              name: other.name,
              value: values.value,
            },
          });
        }
      }}
    />
  );
};

export default NumberMaskInput;
