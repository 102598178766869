import React, { useEffect, useState } from 'react';
import moment from 'moment-timezone';
import { CircularProgress, Typography } from '@mui/material';
import styles from '../OrderPlacedPaid.module.scss';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

dayjs.extend(utc);
dayjs.extend(timezone);

export const Timer = ({
  startDate,
  orderId,
  setOrderData,
  setOrdersData,
  minutes,
  setOrderItems,
}) => {
  const [timeLeft, setTimeLeft] = useState('');
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const timezone = dayjs.tz.guess();
    const startDateByTimezone = dayjs.utc(startDate).tz(timezone).format('YYYY-MM-DD HH:mm:ss');
    const endDate = moment(startDateByTimezone).add(minutes, 'minutes');
    const updateCountdown = () => {
      const now = moment();
      if (now.isAfter(endDate)) {
        if (orderId) {
          setOrdersData((current) => {
            return current.map((order) => {
              if (order.id === orderId) {
                return { ...order, status: 'canceled' };
              }
              return order;
            });
          });
        } else {
          setOrderData((current) => ({ ...current, status: 'canceled' }));
          setOrderItems((current) =>
            current.map((item) => ({
              ...item,
              status: 'canceled',
            }))
          );
        }
      } else {
        const duration = moment.duration(endDate.diff(now));
        const days = Math.floor(duration.asDays());
        const hours = duration.hours();
        const minutes = duration.minutes();
        const seconds = duration.seconds();

        let formattedTime = '';
        if (days > 0) {
          formattedTime += `${String(days).padStart(2, '0')} days `;
        }
        if (hours > 0 || days > 0) {
          formattedTime += `${String(hours).padStart(2, '0')}:`;
        }
        formattedTime += `${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`;

        setTimeLeft(formattedTime);
      }
      setIsLoading(false);
    };

    updateCountdown();

    const intervalId = setInterval(updateCountdown, 1000);

    return () => clearInterval(intervalId);
  }, [startDate]);

  return isLoading ? (
    <CircularProgress className={styles.timerLoader} />
  ) : timeLeft ? (
    <Typography className={styles.timer}>{timeLeft}</Typography>
  ) : null;
};
