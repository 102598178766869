import React, { useContext, useEffect, useState } from 'react';
import { Button, CircularProgress, Modal, ReloadIcon } from 'tt-ui-kit';
import { openNotification } from 'tt-ui-lib/core';
import Box from '@mui/material/Box';
import styles from './ProductReviewModal.module.scss';
import Typography from '@mui/material/Typography';
import Image from '../Image';
import { ScrollContainer } from 'react-indiana-drag-scroll';
import { AppAvatar } from '../../modules/tt-navmenu';
import TextField from '@mui/material/TextField/TextField';
import { Controller, useFieldArray, useFormContext } from 'react-hook-form';
import { useDropzone } from 'react-dropzone';
import { v1 as uuidv1 } from 'uuid';
import axios from 'axios';
import { useLazyQuery, useMutation } from '@apollo/client';
import { CREATE_PRODUCT_REVIEW, GET_URL_FOR_PUBLIC_UPLOAD } from '../../api';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import LinearProgress from '@mui/material/LinearProgress/LinearProgress';
import { FormHelperText } from '@mui/material';
import { styled } from '@mui/material/styles';
import Rating from '@mui/material/Rating';
import StarIcon from '@mui/icons-material/Star';
import StarBorderIcon from '@mui/icons-material/StarBorder';
import PlayCircleIcon from '@mui/icons-material/PlayCircle';
import userContext from '../../context/User/userContext';
import { getAvatarPathBySize } from '../../utils/appUtils';

const StyledRating = styled(Rating)({
  '& .MuiRating-iconFilled': {
    color: '#F9CB00',
  },
  '& .MuiRating-iconHover': {
    color: '#F9CB00',
  },
});

const ProductReviewModal = ({ title, orderGroups, open, handleClose, methods }) => {
  const {
    register,
    control,
    watch,
    setValue,
    getValues,
    clearErrors,
    formState: { errors },
  } = useFormContext();

  const { user } = useContext(userContext);
  const [activeReviewItem, setActiveReviewItem] = useState({});
  const [files, setFiles] = useState([]);
  const [reviewMedia, setReviewMedia] = useState([]);
  const [fileToS3, setFileToS3] = useState(null);
  const [loading, setLoading] = useState(true);
  const [submitDisabled, setSubmitDisabled] = useState(false);
  const [errorImage, setErrorImage] = useState(false);
  const [ratingError, setRatingError] = useState(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const [clickedStarIndex, setClickedStarIndex] = useState(null);
  const [createReview] = useMutation(CREATE_PRODUCT_REVIEW);
  const [getUrlForPublicUpload] = useLazyQuery(GET_URL_FOR_PUBLIC_UPLOAD, {
    variables: { input: { fileToS3 } },
  });
  const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
    accept: {
      'image/png': ['.png', '.jpeg', '.jpg'],
      'video/mp4': ['.mp4', '.MP4'],
    },
    multiple: false,
    onDrop: (files) => onDropped(files),
  });

  const {
    fields: reviewFields,
    append: reviewAppend,
    remove: reviewRemove,
  } = useFieldArray({
    control,
    name: 'reviews',
    keyName: 'key',
  });

  useEffect(() => {
    setActiveReviewItem({
      id: orderGroups.orderItems[0].shopProduct.id,
      company: orderGroups.company,
      product: orderGroups.orderItems[0].shopProduct,
      productPrice: orderGroups.orderItems[0].shopProductPrice,
    });
    if (reviewFields && !reviewFields.length) {
      reviewRemove(0);
      addReview(orderGroups.orderItems[0].shopProduct.id);
    }
    setLoading(false);
  }, []);

  useEffect(() => {
    if (errorImage && reviewMedia.length <= 5) {
      setErrorImage(false);
    }
  }, [reviewMedia]);

  const addReview = (id) => {
    const data = {
      id: id,
      comment: '',
      rating: null,
      reviewMedia: [],
    };
    reviewAppend(data);
  };

  const clearEmptyReview = () => {
    const activeIndex = reviewFields.findIndex((item) => item.id === activeReviewItem.id);
    const currentItem = getValues(`reviews[${activeIndex}]`);
    if (currentItem && activeIndex >= 0) {
      if (!currentItem.comment && !currentItem.rating && !currentItem.reviewMedia.length) {
        reviewRemove(activeIndex);
      }
    }
  };

  const validationImage = (file, accepts) => {
    return !!accepts.find((accept) => accept === file?.type);
  };

  const onDropped = (files) => {
    if (reviewMedia.length < 6) {
      if (validationImage(files[0], ['image/png', 'image/jpg', 'image/jpeg', 'video/mp4'])) {
        const fileType = files[0].type.split('/')[0];
        let errorMessage;
        let isAvailableFile;
        const getFile = (media) => media.file.type.split('/')[0] === fileType;
        let fileTypeForS3 = '';
        if (fileType === 'image') {
          const imagesData = reviewMedia.filter(getFile);
          isAvailableFile = imagesData.length < 5;
          errorMessage = '5 images';
          fileTypeForS3 = 'review_image_main';
        } else {
          const videoData = reviewMedia.find(getFile);
          isAvailableFile = !videoData;
          errorMessage = '1 video';
          fileTypeForS3 = 'review_video_main';
        }
        if (isAvailableFile) {
          fileFormatMaker(files[0], fileTypeForS3);
          setErrorImage('');
        } else {
          setErrorImage(`You can't add more than ${errorMessage}`);
        }
      } else {
        setErrorImage('You can add only .jpg, .png, .mp4 files');
      }
    } else {
      setErrorImage("You can't add more than 6 files");
    }
  };

  const fileFormatMaker = async (file, type) => {
    const fileSize = (file.size / 1024 / 1024).toFixed(2);
    if (fileSize <= 2) {
      Object.assign(file, {
        preview: URL.createObjectURL(file),
      });
      const reader = new FileReader();
      const uploadImageIndex = reviewMedia.length;
      reader.onload = (e) => {
        setReviewMedia((current) => [
          ...current,
          { image: e.target.result, progress: 0, error: false, file: file },
        ]);
      };
      reader.readAsDataURL(file);
      await uploadFile(file, type, uploadImageIndex);
    } else {
      openNotification({
        message: 'Allowed files with 2MB or below.',
        type: 'info',
      });
    }
  };

  const uploadFile = async (file, type, uploadImageIndex) => {
    const activeIndex = reviewFields.findIndex((item) => item.id === activeReviewItem.id);
    try {
      const formatFile = file?.name.substr(-4, 4);
      let filesName = '';
      if (formatFile === '.') {
        filesName = `${uuidv1()}.${file?.name}${formatFile}`;
      } else {
        filesName = `${uuidv1()}.${file?.name}`;
      }
      const toUpload = { type: type, name: filesName };
      setFileToS3(toUpload);
      const { data } = await getUrlForPublicUpload({
        variables: { input: toUpload },
      });
      setFileToS3(null);
      const urlToUpload = data?.getUrlForPublicUpload?.url;

      await axios
        .put(urlToUpload, file, {
          headers: {
            'Content-type': file.type,
            'Access-Control-Allow-Origin': '*',
          },
          onUploadProgress: (progressEvent) => {
            const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
            const imagesData = [];
            setReviewMedia((current) =>
              current.map((item, index) => {
                if (index === uploadImageIndex) {
                  imagesData.push({ ...item, progress: 100 });
                  return { ...item, progress: percentCompleted };
                }
                imagesData.push(item);
                return item;
              })
            );
            if (imagesData.length) {
              setValue(`reviews[${activeIndex}].reviewMedia`, imagesData);
            }
          },
        })
        .then(() => {
          const imageItem = {
            original_name: file.name,
            uuid_name: filesName,
            extension: formatFile.slice(1),
            size: file.size,
            type: type,
          };
          const reviewImagesData = getValues(`reviews[${activeIndex}].images`) || [];
          setValue(`reviews[${activeIndex}].images`, [...reviewImagesData, imageItem]);
        })
        .catch(() => {
          setReviewMedia((current) => {
            return current.map((item, index) => {
              if (index === uploadImageIndex) {
                return { ...item, error: true, progress: 0 };
              }
              return item;
            });
          });
        });
    } catch (error) {
      console.log('error !!!', error);
    }
  };

  useEffect(() => {
    const activeIndex = reviewFields.findIndex((item) => item.id === activeReviewItem.id);
    const currentImages = getValues(`reviews[${activeIndex}].reviewMedia`) || [];
    setReviewMedia(currentImages);
  }, [activeReviewItem]);

  const submitReview = async (data) => {
    setSubmitDisabled(true);
    const activeIndex = reviewFields.findIndex((item) => item.id === activeReviewItem.id);
    const currentItem = getValues(`reviews[${activeIndex}]`);
    if (currentItem && activeIndex >= 0) {
      if ((currentItem.comment || currentItem.reviewMedia.length) && !currentItem.rating) {
        openNotification({
          type: 'error',
          message: 'if comment or images are exist rating is required',
        });
      } else {
        const reviewData = data.reviews.filter((item) => item.rating);
        if (reviewData && reviewData.length) {
          const inputData = reviewData.map((item) => {
            return {
              reviewable_id: item.id,
              reviewable_type: 'product',
              user_id: user.id,
              stars: item.rating,
              comment: item.comment,
              files: item.images,
            };
          });
          const createReviewResponse = await createReview({
            variables: {
              input: {
                products: inputData,
              },
            },
            fetchPolicy: 'network-only',
          });
          if (
            createReviewResponse.data &&
            createReviewResponse.data.createReview &&
            createReviewResponse.data.createReview.length
          ) {
            handleClose();
            methods.reset();
            openNotification({
              type: 'success',
              message: 'Feedback on product has been sent',
            });
          }
        } else {
          openNotification({
            type: 'error',
            message: 'Please rate at least one item before submitting.',
          });
        }
      }
    }
    setSubmitDisabled(false);
  };

  const reloadImage = async (image, index) => {
    setFiles((current) => current.filter((item, i) => index !== i));
    setReviewMedia((current) => {
      return current.map((item, i) => {
        if (i === index) {
          return { ...item, progress: 0, error: false };
        }
        return item;
      });
    });
    const fileType = image.file.type.split('/')[0];

    let fileTypeForS3 = fileType === 'image' ? 'review_image_main' : 'review_video_main';
    await uploadFile(image.file, fileTypeForS3, index);
  };

  const removeImage = async (logoId = null, index) => {
    const activeIndex = reviewFields.findIndex((item) => item.id === activeReviewItem.id);
    setReviewMedia((currentImages) => {
      const newImagesData = currentImages.filter((item, i) => index !== i);
      setValue(`reviews[${activeIndex}].reviewMedia`, newImagesData);
      const reviewImagesData = getValues(`reviews[${activeIndex}].images`);
      const filteredImages = reviewImagesData.filter((item, i) => index !== i);
      setValue(`reviews[${activeIndex}].images`, filteredImages);
      return newImagesData;
    });
  };

  const handlePlay = (id) => {
    const video = document.getElementById(id);
    if (isPlaying) {
      video.pause();
      setIsPlaying(false);
    } else {
      video.play();
      setIsPlaying(true);
    }
  };

  const changeActiveReviewItem = (id, company, product, productPrice) => {
    const activeIndex = reviewFields.findIndex((item) => item.id === activeReviewItem.id);
    const currentItem = watch(`reviews[${activeIndex}]`);
    if ((currentItem.comment || currentItem.reviewMedia.length) && !currentItem.rating) {
      openNotification({
        type: 'error',
        message: 'if comment or images are exist rating is required',
      });
    } else {
      clearEmptyReview();
      setActiveReviewItem({
        id,
        company,
        product,
        productPrice,
      });
      const checkExistItem = reviewFields.find((item) => item.id === product.id);

      if (!checkExistItem) {
        addReview(product.id);
      }
    }
  };

  return (
    <Modal
      open={open}
      onClose={() => handleClose()}
      onClick={(e) => e.stopPropagation()}
      title={title}
      className={styles.modalContainer}
    >
      <Box className={styles.modalBody}>
        {loading ? (
          <CircularProgress />
        ) : (
          <>
            <Typography className={styles.subtitle}>
              Leave feedback on the products you buy
            </Typography>
            <ScrollContainer hideScrollbars={false} className={styles.scrollContainer}>
              <Box className={styles.slider}>
                {orderGroups?.orderItems?.length > 0 &&
                  orderGroups.orderItems.map((product) => (
                    <Box
                      key={product.id}
                      className={styles.sliderImage}
                      onClick={() => {
                        product.shopProduct.id !== activeReviewItem.id &&
                          changeActiveReviewItem(
                            product.shopProduct.id,
                            orderGroups.company,
                            product.shopProduct,
                            product.shopProductPrice
                          );
                      }}
                      style={{
                        border:
                          activeReviewItem.product.id === product.shopProduct.id
                            ? '1px solid #1F4A9D'
                            : '1px solid transparent',
                      }}
                    >
                      <Image
                        className={styles.image}
                        src={
                          getAvatarPathBySize(product?.shopProduct?.logos, 'mediumPath') ||
                          '/images/market-place/no_image.png'
                        }
                      />
                    </Box>
                  ))}
              </Box>
            </ScrollContainer>
            <Box className={styles.reviewsBlock}>
              <Box className={styles.companyInfo}>
                <AppAvatar
                  nav={0}
                  size="xs"
                  userName={activeReviewItem.company.company_name}
                  src={getAvatarPathBySize(activeReviewItem.company.logos, 'smallPath')}
                />
                <Typography className={styles.companyName}>
                  {activeReviewItem?.company.company_name}
                </Typography>
              </Box>
              <Box className={styles.productName}>
                <Typography className={styles.name}>{activeReviewItem?.product.name}</Typography>
                {activeReviewItem.productPrice?.unit_value &&
                  activeReviewItem.productPrice?.unit.name && (
                    <Typography className={styles.price}>
                      {activeReviewItem.productPrice?.unit_value}{' '}
                      {activeReviewItem.productPrice?.unit.name};
                    </Typography>
                  )}
              </Box>
            </Box>
            {reviewFields.map(
              (item, index) =>
                item.id === activeReviewItem.id && (
                  <>
                    <Box className={styles.stars}>
                      <Box className={styles.starsBlock}>
                        <Controller
                          name={`reviews[${index}].rating`}
                          control={control}
                          defaultValue={null}
                          render={({ field }) => (
                            <StyledRating
                              precision={0.5}
                              {...field}
                              onChange={(event, value) => {
                                field.onChange(value);
                              }}
                              icon={<StarIcon />}
                              emptyIcon={<StarBorderIcon />}
                            />
                          )}
                        />
                        <Typography className={styles.text}>Rate the product</Typography>
                      </Box>
                    </Box>
                    <TextField
                      {...register(`reviews[${index}].comment`, {
                        pattern: {
                          value: /^.{1,500}$/gm,
                          message: "You can't write more than 500 characters",
                        },
                      })}
                      multiline
                      rows={2}
                      inputProps={{ style: { resize: 'vertical' } }}
                      className={styles.textarea}
                      label="Describe the product"
                    />
                    <Box className={styles.imagesBlock}>
                      <Box className={styles.imagesDropContainer} {...getRootProps()}>
                        <input type="hidden" {...getInputProps()} />
                        <Box className={styles.iconContainer}>
                          <Image src="/images/addImage.svg" width={29.33} height={26.67} />
                        </Box>
                        <Box className={styles.imagesBlockText}>
                          <div className={styles.imagesBlockTitle}>Add images</div>
                          <div>Select and drop your file here.</div>
                          <div className={styles.imagesBlockDescription}>
                            You can add .jpg, .png or .mp4 file with the maximum size 2 MB.
                          </div>
                        </Box>
                      </Box>
                    </Box>
                    {reviewMedia.length > 0 && (
                      <ScrollContainer hideScrollbars={false} className={styles.scrollContainer}>
                        <Box className={styles.slider}>
                          {reviewMedia.map((item, imageIndex) => (
                            <Box
                              key={imageIndex}
                              className={styles.sliderImageUploaded}
                              sx={{
                                border: `1.8px solid ${item.error ? '#D32F2F' : 'transparent'}`,
                              }}
                            >
                              {item.file.type.includes('image') ? (
                                <Image src={item.image} className={styles.imageUploaded} />
                              ) : (
                                <>
                                  <video
                                    autoPlay={false}
                                    controls={false}
                                    className={styles.imageUploaded}
                                    src={item.image}
                                    id={`video-${imageIndex}`}
                                    onClick={() => handlePlay(`video-${imageIndex}`)}
                                  />
                                  <Box
                                    onClick={() => handlePlay(`video-${imageIndex}`)}
                                    className={styles.playerIcon}
                                    sx={{ display: isPlaying ? 'none' : 'block' }}
                                  >
                                    <PlayCircleIcon
                                      className={styles.videoPlayBtn}
                                      fontSize="large"
                                    />
                                  </Box>
                                </>
                              )}
                              <Box
                                className={styles.removeImage}
                                onClick={() => removeImage(item.id, imageIndex)}
                              >
                                <CloseOutlinedIcon fontSize="medium" style={{ color: '#FFFFFF' }} />
                              </Box>
                              {item.progress < 100 && (
                                <Box className={styles.imageLoader}>
                                  {item.error ? (
                                    <Box
                                      className={styles.reloadBtn}
                                      onClick={() => reloadImage(item, imageIndex)}
                                    >
                                      <ReloadIcon width={24} height={24} />
                                    </Box>
                                  ) : (
                                    <LinearProgress
                                      className={styles.lineProgress}
                                      value={item.progress}
                                      variant="determinate"
                                    />
                                  )}
                                </Box>
                              )}
                            </Box>
                          ))}
                        </Box>
                      </ScrollContainer>
                    )}
                    {errorImage && (
                      <FormHelperText sx={{ color: 'red' }}>{errorImage}</FormHelperText>
                    )}
                  </>
                )
            )}
            <Button
              type="primary"
              className={styles.button}
              isSubmit
              onClick={() => {
                methods.handleSubmit(submitReview)();
              }}
              loading={submitDisabled}
              disabled={submitDisabled}
            >
              send
            </Button>
          </>
        )}
      </Box>
    </Modal>
  );
};

export default ProductReviewModal;
