import React, { useContext, useEffect, useState } from 'react';
import styles from './ProductsPage.module.scss';
import { Typography, Box } from '@mui/material';
import Image from '../../Image';
import { useLocation, useNavigate } from 'react-router-dom';
import userContext from '../../../context/User/userContext';

const MARKET_BAR = [
  {
    title: 'Products',
    icon: '/images/market-place/products.svg',
    iconWidthDesc: 70,
    iconWidthMobile: 50,
    iconHeightDesc: 70,
    iconHeightMobile: 50,
    to: '/dashboard/marketplace/list/product?activeTab=products',
  },
  {
    title: 'TT Carbon Registry',
    icon: '/images/market-place/registry.svg',
    iconWidthDesc: 70,
    iconWidthMobile: 50,
    iconHeightDesc: 70,
    iconHeightMobile: 50,
    to: '/dashboard/marketplace/list/product?activeTab=carbon',
  },
];

const MARKET_BAR_BOTTOM = [
  {
    title: 'Services & Solutions',
    icon: '/images/market-place/services.svg',
    iconWidthDesc: 70,
    iconWidthMobile: 50,
    iconHeightDesc: 70,
    iconHeightMobile: 50,
    to: '/dashboard/marketplace/list/product?activeTab=services',
  },
  ,
  {
    title: 'Software & Tools',
    icon: '/images/market-place/software.svg',
    iconWidthDesc: 70,
    iconWidthMobile: 50,
    iconHeightDesc: 70,
    iconHeightMobile: 50,
    to: `/dashboard/settings`,
  },
];
const ProductsPage = () => {
  const navigate = useNavigate();
  const { user } = useContext(userContext);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const location = useLocation();

  useEffect(() => {
    if (
      location &&
      location.state &&
      location.state.action &&
      location.state.action.isOpenNotification
    ) {
      location.state.action.openNotification();
      navigate(location.pathname, {});
    }
  }, [user, location]);

  useEffect(() => {
    localStorage.setItem('currentPage', JSON.stringify(1));
    window.addEventListener('resize', () => {
      setWindowWidth(window.innerWidth);
    });
  }, []);

  return (
    <Box>
      <Box className={styles.middleContent}>
        {MARKET_BAR.map((market) => (
          <Box
            key={market.title}
            className={styles.marketPlaceItem}
            onClick={() => navigate(market.to)}
          >
            <Box className={styles.marketPlaceItemContent}>
              <Image
                src={market.icon}
                width={windowWidth > 375 ? market.iconWidthDesc : market.iconWidthMobile}
                height={windowWidth > 375 ? market.iconHeightDesc : market.iconHeightMobile}
                layout="fixed"
              />
              <Typography className={styles.subTitle}>{market.subTitle}</Typography>
              <Typography className={styles.title}>{market.title}</Typography>
            </Box>
            <Image
              className={styles.arrow}
              src="/images/market-place/arrow.svg"
              width={12}
              height={20}
              layout="fixed"
            />
          </Box>
        ))}
      </Box>
      <Box className={styles.middleContent}>
        {MARKET_BAR_BOTTOM.map((market) => (
          <Box
            key={market.title}
            className={styles.marketPlaceItem}
            onClick={() => navigate(market.to)}
          >
            <Box className={styles.marketPlaceItemContent}>
              <Image
                src={market.icon}
                width={windowWidth > 375 ? market.iconWidthDesc : market.iconWidthMobile}
                height={windowWidth > 375 ? market.iconHeightDesc : market.iconHeightMobile}
                layout="fixed"
              />
              <Typography className={styles.subTitle}>{market.subTitle}</Typography>
              <Typography className={styles.title}>{market.title}</Typography>
            </Box>
            <Image
              className={styles.arrow}
              src="/images/market-place/arrow.svg"
              width={12}
              height={20}
              layout="fixed"
            />
          </Box>
        ))}
      </Box>
    </Box>
  );
};

export default ProductsPage;
