import React, { useEffect, useState } from 'react';
import { useLazyQuery, useMutation } from '@apollo/client';
import ReactPhoneInput from 'react-phone-input-2';
import {
  Button,
  Modal,
  PartialLoader,
  Select,
  Input,
  InputTextArea,
  Form,
  openNotification,
} from 'tt-ui-lib/core';
import { SendIcon } from 'tt-ui-lib/icons';
import styles from './Modal.module.scss';
import { parseErrors } from '../../utils/validation';

const whitespacesReg = /^\s*\S.*$/;
const specialCharReg = /^[^<>'"/;`%]*$/;
const numberReg = /^([^0-9]*)$/;
const phoneRegExp =
  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

// const schema = yup.object({
//   first_name: yup
//     .string()
//     .required('The first name field must not be empty.')
//     .max(255, 'Too many characters.')
//     .matches(whitespacesReg, 'The field cannot contain only spaces.')
//     .matches(specialCharReg, 'Special characters are prohibited.')
//     .matches(numberReg, 'Numbers are prohibited.'),
//   last_name: yup
//     .string()
//     .max(255, 'Too many characters.')
//     .matches(whitespacesReg, 'The field cannot contain only spaces.')
//     .matches(specialCharReg, 'Special characters are prohibited.')
//     .matches(numberReg, 'Numbers are prohibited.'),
//   role_id: yup.string().required('This is a required field.'),
//   email: yup
//     .string()
//     .required('Please enter your email address.')
//     .email('Please enter a valid email address, e.g. joe@mail.com.'),
// });

export const ModalInv = ({
  visible = false,
  title = '',
  onClose,
  roleQuery,
  sendInviteQuery,
  setInvitations = false,
}) => {
  const [form] = Form.useForm();

  const [roleData, setRoleData] = useState(null);
  const [loader, setLoader] = useState(false);
  const [phoneInputLabel, setPhoneInputLabel] = useState('');
  const [getRoles] = useLazyQuery(roleQuery);
  // const {
  //   handleSubmit,
  //   setValue,
  //   formState: { errors },
  //   control,
  //   reset,
  //   setError,
  // } = useForm({
  //   resolver: yupResolver(schema),
  //   mode: 'all',
  //   defaultValues: {
  //     type: 0,
  //     first_name: '',
  //     last_name: '',
  //     email: '',
  //     tel: '',
  //     role_id: '',
  //     comment: '',
  //     note: '',
  //     max_count: 1,
  //   },
  // });

  const [sendModal] = useMutation(sendInviteQuery, {
    onError: (graphQLErrors) => {
      const errorsList = parseErrors({ errors: graphQLErrors });
      if (errorsList.email) {
        form.setFields([
          {
            name: 'email',
            errors: ['The email has already been taken.'],
          },
        ]);
      }
    },
  });

  const getRolesFromApi = async () => {
    const roleBase = await getRoles();
    setRoleData(roleBase?.data?.roles || []);
  };

  const Close = () => {
    onClose();
  };

  useEffect(() => {
    getRolesFromApi().then();
  }, []);

  const submit = async (values) => {
    setLoader(true);

    const formData = {
      ...values,
      max_count: 1,
      type: 0,
    };

    const data = await sendModal({ variables: { input: formData } });

    if (data?.errors) {
      openNotification({
        message: 'Something went wrong, try again',
        type: 'error',
      });
      setLoader(false);
    }

    if (!data?.errors) {
      if (setInvitations) {
        setInvitations((current) => [data.data.sendInvitation.invitation, ...current.slice(0, -1)]);
      }
      openNotification({
        message: 'Your invitation has been sent successfully',
        type: 'success',
      });
      setLoader(false);
      form.resetFields();
      Close();
    }
  };

  const validateBeforeSubmit = async (values) => {
    await submit(values);
  };

  // const onChangeInput = (name, value) => {
  //   setValue(name, value);
  // };

  // const renderSelectValue = (val) => {
  //   const roles =
  //     roleData?.map((element) => ({
  //       name: element.name.charAt(0).toUpperCase() + element.name.slice(1),
  //       value: element.id,
  //     })) || [];
  //   const roleItem = roles.find((item) => item.value === val);
  //   return roleItem?.name || null;
  // };

  return (
    <Modal
      open={visible}
      onClose={() => onClose()}
      onClick={(e) => e.stopPropagation()}
      title={title}
    >
      <Form form={form} onFinish={validateBeforeSubmit} className={styles.modalBody}>
        <div className={styles.modal_content}>
          <div className={styles.modal_content_first}>
            <div className={styles.row}>
              <div className={styles.modal_input}>
                <Form.Item
                  name="first_name"
                  rules={[
                    {
                      required: true,
                      message: 'Please fill out the rest fields. This is mandatory.',
                    },
                    {
                      max: 35,
                      message: 'Too many characters in first name.',
                    },
                    {
                      pattern: whitespacesReg,
                      message: 'The field cannot contain only spaces.',
                    },
                    {
                      pattern: specialCharReg,
                      message: 'Special characters are prohibited.',
                    },
                  ]}
                >
                  <Input label="First Name" style={{ width: '100%' }} />
                </Form.Item>
              </div>

              <div className={styles.modal_input}>
                <Form.Item
                  name="last_name"
                  rules={[
                    {
                      required: true,
                      message: 'Please fill out the rest fields. This is mandatory.',
                    },
                    {
                      max: 35,
                      message: 'Too many characters in first name.',
                    },
                    {
                      pattern: whitespacesReg,
                      message: 'The field cannot contain only spaces.',
                    },
                    {
                      pattern: specialCharReg,
                      message: 'Special characters are prohibited.',
                    },
                  ]}
                >
                  <Input label="Last Name" style={{ width: '100%' }} />
                </Form.Item>
              </div>

              <div className={styles.modal_select}>
                <Form.Item
                  name="role_id"
                  rules={[{ required: true, message: 'Please select a role' }]}
                >
                  <Select
                    options={
                      roleData?.map((element) => ({
                        label: element.name.charAt(0).toUpperCase() + element.name.slice(1),
                        value: element.id,
                      })) || []
                    }
                    label="Suggest a role"
                  />
                </Form.Item>
              </div>
            </div>

            <div className={styles.row}>
              <div className={styles.modal_input} style={{ width: '360px' }}>
                <Form.Item
                  name="email"
                  rules={[{ required: true, message: 'Please enter your email' }]}
                >
                  <Input label="Email" style={{ width: '100%' }} />
                </Form.Item>
              </div>

              <div>
                <Form.Item
                  name="tel"
                  rules={[
                    {
                      required: true,
                      message: 'Please fill out the rest fields. This is mandatory.',
                    },
                    {
                      pattern: phoneRegExp,
                      message: 'Phone number is not valid',
                    },
                  ]}
                >
                  <ReactPhoneInput
                    country="us"
                    id="phone-input"
                    specialLabel=""
                    placeholder="Phone number"
                    buttonClass={styles.phone}
                    inputClass={styles.phoneInput}
                  />
                </Form.Item>
              </div>
            </div>
          </div>

          <div className={styles.row}>
            <div style={{ width: '100%' }}>
              <Form.Item name="comment">
                <InputTextArea label="Comment for the invitee" />
              </Form.Item>
            </div>
          </div>

          <div className={styles.row}>
            <div style={{ width: '100%' }}>
              <Form.Item name="note">
                <InputTextArea label="Note for me" />
              </Form.Item>
            </div>
          </div>
        </div>

        <div className={styles.modal_button}>
          <div style={{ display: 'flex', justifyItems: 'flex-start' }}>
            <Button type="default" onClick={Close}>
              CANCEL
            </Button>
          </div>

          <div style={{ display: 'flex', justifyItems: 'flex-end' }}>
            <Button
              icon={<SendIcon className={styles.sendIcon} />}
              loading={loader}
              type="primary"
              onClick={() => form.submit()}
            >
              SEND
            </Button>
          </div>
        </div>
      </Form>

      {/* {loader === true && (
        <div>
          <PartialLoader />
        </div>
      )} */}
    </Modal>
  );
};
