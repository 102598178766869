import { useState } from 'react';
import { Modal } from 'tt-ui-kit';
import AddCardForm from '../../../../../components/AddCardForm';
import styles from './AddNewCardModal.module.scss';

const AddNewCardModal = ({ open, setOpen, setCardsList }) => {
  const [value, setValue] = useState();
  const closeModal = () => {
    setOpen(false);
  };

  return (
    <Modal
      title="Add card"
      open={open}
      onClose={closeModal}
      closeOnlyByControls={false}
      className={styles.modal}
    >
      <AddCardForm setCardsList={setCardsList} closeModal={closeModal} />
    </Modal>
  );
};
export default AddNewCardModal;
