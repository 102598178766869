import { Button, Modal } from 'tt-ui-kit';
import styles from './ConnectStripeModal.module.scss';
import React from 'react';
import { Box, Typography } from '@mui/material';
import StripeIcon from '../../icons/StripeIcon';

const ConnectStripeModal = ({ open, onClose, companyId, modalAction, accountLink = null }) => {
  const verificationModal = modalAction === 'continue';
  const openWindow = (pageName, id) => {
    if (typeof window !== 'undefined' && id) {
      let url = `${process.env.REACT_APP_MARKET}/${pageName}?basePage=${window.location.pathname}&id=${id}`;
      window.open(`${url}`, '_blank');
    }
  };

  const openStripeDashboard = (link) => {
    window.open(`${link}`, '_blank');
  };

  return (
    <Modal
      title={
        verificationModal ? 'Please complete your Stripe verification' : 'Connect payment system'
      }
      open={open}
      onClose={onClose}
      className={styles.modal}
      closeOnlyByControls
    >
      <Box className={styles.body}>
        <Typography>
          Please complete your Stripe verification to start selling your products or services. Once
          verified, you&apos;ll have unrestricted access to all features.
        </Typography>
        {verificationModal ? (
          <Box className={styles.verifyModalActions}>
            <Button onClick={onClose} type="mainButton" className={styles.button}>
              cancel
            </Button>
            <Button
              className={styles.button}
              onClick={(e) => {
                e.stopPropagation();
                openStripeDashboard(accountLink);
              }}
              type="primary"
            >
              go to stripe account
            </Button>
          </Box>
        ) : (
          <Box className={styles.modalAction}>
            <button
              onClick={() => openWindow(`payment/registerAccount`, companyId)}
              className={styles.connectStripeButton}
              type="button"
            >
              <StripeIcon />
              <span>CONNECT STRIPE</span>
            </button>
          </Box>
        )}
      </Box>
    </Modal>
  );
};

export default ConnectStripeModal;
