import React, { useState } from 'react';
import {
  GET_AGREEMENT,
  GET_PRO_LOGIN,
  ACCEPT_INVITE,
  CREATE_NEW_DIGITAL_INDIVIDUAL,
} from '../../../api';
import { openNotification } from 'tt-ui-lib/core';
import { SignInUpModal, BlockedModal } from '../../../modules/tt-modals';
// import 'tt-modals/dist/index.css';
import { useMutation } from '@apollo/client';
import Cookies from 'js-cookie';
import { useNavigate } from 'react-router';
import VerifyEmailModal from '../VerifyEmailModal';

export const SignInUpModalWrapper = ({
  open,
  openMode,
  closeModal,
  redirectAfterLoginTo,
  setOpenSupportChat,
  reloadAfterLogin = false,
}) => {
  const router = useNavigate();

  const [isVerifyEmailModalOpen, setIsVerifyEmailModalOpen] = useState(false);
  const [isBlockedModalOpen, setIsBlockedModalOpen] = useState(false);
  const [email, setEmail] = useState('');

  const [getLogin] = useMutation(GET_PRO_LOGIN, {
    variables: { input: {} },
  });
  const [createNewDigitalIndividual] = useMutation(CREATE_NEW_DIGITAL_INDIVIDUAL);
  const [getConfirmInviteCode] = useMutation(ACCEPT_INVITE, {
    variables: { input: {} },
  });

  const handleClose = () => {
    setIsVerifyEmailModalOpen(false);
    setEmail('');
  };

  const loginHandler = async (data, setError, onFinish) => {
    const res = await getLogin({
      variables: {
        input: data,
      },
      onError: (graphQLErrors) => {
        if (graphQLErrors) {
          const error = `\n${graphQLErrors}`.split('Error:')[1];
          if (error.includes('Your account is currently blocked')) {
            setIsBlockedModalOpen(true);
          } else {
            setError(error);
          }
        }
      },
    });

    if (!res.errors && res.data.login.access_token) {
      const domainValue = process.env.REACT_APP_DOMAIN;
      Cookies.set('access_token', res.data.login.access_token, {
        domain: domainValue,
        secure: true,
      });
      Cookies.set('refresh_token', res.data.login.refresh_token, {
        domain: domainValue,
      });
      Cookies.set('userId', res.data.login.user.id, { domain: domainValue, secure: true });

      if (redirectAfterLoginTo) {
        window.location.href = redirectAfterLoginTo;
      } else if (reloadAfterLogin) {
        window.location.reload();
      } else {
        window.location.href = '/dashboard/marketplace';
      }
      // closeModal();
    }

    onFinish();
  };

  const signUpHandler = async (data, onFinish, onError) => {
    try {
      const newUser = await createNewDigitalIndividual({
        variables: { input: data },
      });

      if (!newUser.errors && newUser.data.registerIndividual.tokens) {
        setEmail(data.email);
        setIsVerifyEmailModalOpen(true);
        closeModal();
      }

      onFinish();
    } catch (e) {
      if (e?.message === 'Validation failed for the field [registerIndividual].') {
        openNotification({
          message: 'This email already taken, plaease choose another email.',
          type: 'error',
        });
        onError();
      } else {
        onError(e);
      }
    }
  };

  const signUpRefHandler = async (data, setError, onFinish) => {
    const res = await getConfirmInviteCode({
      variables: {
        input: data,
      },
      onError: (graphQLErrors) => {
        if (graphQLErrors) {
          setError(`\n${graphQLErrors}`.split('Error:')[1]);
        }
      },
    });

    if (!res.errors && res.data.acceptInvitation.url) {
      router(`/${res.data.acceptInvitation.url}`);
      closeModal();
    }

    onFinish();
  };

  const signInWithGoogle = () => {
    const url = `${process.env.REACT_APP_SSO}/login/google?registrationType=individual&sign_in=true`;
    window.location.replace(url);
  };

  return (
    <>
      <SignInUpModal
        signInWithGoogle={signInWithGoogle}
        open={open}
        openMode={openMode}
        closeModal={closeModal}
        loginHandler={loginHandler}
        signUpHandler={signUpHandler}
        signUpRefHandler={signUpRefHandler}
        agreementSchema={GET_AGREEMENT}
      />
      <VerifyEmailModal
        isModelOpen={isVerifyEmailModalOpen}
        setIsOpen={setIsVerifyEmailModalOpen}
        handleClose={() => handleClose()}
        title="Verify your email"
        text={`We have sent an email to ${email || 'your address'} to verify your email address.`}
        subText="Please verify your email and proceed to sign up."
        isDashboard
      />
      <BlockedModal
        open={isBlockedModalOpen}
        onClose={() => setIsBlockedModalOpen(false)}
        openSupport={() => {
          closeModal();
          setIsBlockedModalOpen(false);
          if (setOpenSupportChat) {
            setOpenSupportChat(true);
          }
        }}
      />
    </>
  );
};
