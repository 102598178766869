import React, { useEffect, useState } from 'react';
import { Button, Modal, Loader, openNotification, CheckBox } from 'tt-ui-lib/core';
import styles from './DepositModal.module.scss';
import { useSelectedContext } from '../../../../../context/contextSelector';
import { useMutation } from '@apollo/client';
import { CREATE_CERT_ORDER_WITH_DEPOSIT } from '../../../../../api';
import {
  useDigitalAssets,
  useDigitalAssetsCertificate,
} from '../../../../../modules/tt-digital-assets-provider';
import { useNavigate } from 'react-router';

const DepositModal = ({
  open,
  handleClose,
  id,
  deposit,
  quantity,
  unit,
  wallet,
  discountPrice = 0,
}) => {
  const { certificateOrderDeposit } = useDigitalAssetsCertificate();
  const { showStandby, hideStandby } = useDigitalAssets();
  const navigate = useNavigate();

  const { user, selectedCompany } = useSelectedContext((context) => context.user);

  const [readAndAgree, setReadAndAgree] = useState(false);
  const [createLoading, setCreateLoading] = useState(false);

  const [createOrderRequest] = useMutation(CREATE_CERT_ORDER_WITH_DEPOSIT);

  const depositConfirm = (orderId) => {
    if (!orderId) {
      openNotification({
        type: 'error',
        message: 'No order ID provided, refresh page an try again',
      });
      return;
    }

    showStandby();

    const payload = {
      id: orderId,
      creatorUid: user.id,
      creator: wallet,
      creditAmount: quantity,
      amount: deposit * quantity,
    };

    certificateOrderDeposit(payload, true)
      .then(() => {
        navigate(`/dashboard/credit/${id}/checkout?orderId=${orderId}`);
        openNotification({
          type: 'success',
          message: 'Success! Please, proceed payment for your order',
        });
        hideStandby();
      })
      .catch((err) => {
        openNotification({
          type: 'error',
          message: `Error while metamask transaction: ${err || err?.message || 'try again'}`,
        });
        hideStandby();
      });
  };

  const createOrder = () => {
    if (!id || !quantity || !unit || !user.id) {
      return;
    }

    setCreateLoading(true);

    const payload = {
      credit_id: id,
      amount: parseInt(quantity),
      unit: unit,
      wallet: wallet,
      fcem_deposit: deposit,
      fcem_discount: parseInt(discountPrice),
      company_id: selectedCompany?.isCompanySelected
        ? selectedCompany?.selectedCompany?.id || undefined
        : undefined,
    };

    createOrderRequest({ variables: { input: payload } })
      .then((response) => {
        if (response.data?.reservationCarbonCredit) {
          depositConfirm(response.data?.reservationCarbonCredit?.order_id);
        } else {
          openNotification({
            type: 'error',
            message: 'Error while reservation credit, try again',
          });
        }
      })
      .catch((err) => {
        openNotification({
          type: 'error',
          message: err?.message
            ? `Error while reservation credit: ${err?.message}`
            : 'Error while reservation credit, try again',
        });
      })
      .finally(() => {
        setCreateLoading(false);
      });
  };

  useEffect(() => {
    if (!open) {
      setReadAndAgree(false);
    }
  }, [open]);

  return (
    <Modal open={open} onClose={handleClose} title="FCEM Deposit" closeOnlyByControls>
      <div className={styles.modalContent}>
        <div>
          To get a discount on purchasing your carbon credit, you need to deposit a certain amount
          of FCEM coins. We are happy to offer FCEM holders a discount of up to 50% of the original
          price of the carbon credit as part of the TT loyalty program, the goal of which is to
          stimulate activity on the voluntary carbon market, including the offsetting of carbon
          credits. For every 4 FCEM you deposit, you will get a 1% discount for purchasing 1 ton of
          carbon credit. This formula applies regardless of the original price per 1 ton of carbon
          credit, meaning that 50% off the original price of 10$ or 20$ per 1 ton will require the
          same amount of 200 FCEM coins. If you are buying 3 tons and want to get 50% off the whole
          purchase, you must deposit 600 FCEM. If you deposit less than 200 FCEM, your discount will
          be less than 50%. Based on this logic the exact discount will be calculated in accordance
          with this formula: D=M/4T, where D is the discount in %, M is the amount of FCEM coins you
          are ready to deposit, and T is the number of tons of carbon credit you are buying.
        </div>
      </div>

      <div className={styles.checkboxGroup}>
        <CheckBox
          label="I have read and agree"
          value={readAndAgree}
          onChange={() => setReadAndAgree(!readAndAgree)}
        />
      </div>

      <div className={styles.modalActions}>
        <Button onClick={handleClose}>CANCEL</Button>
        <Button
          type="primary"
          onClick={() => {
            createOrder();
          }}
          loading={createLoading}
          disabled={!readAndAgree}
        >
          PROCEED
        </Button>
      </div>
    </Modal>
  );
};

export default DepositModal;
