import React from 'react';
import Box from '@mui/material/Box';
import styles from './CertificatesTab.module.scss';

const CertificatesTab = () => {
  return (
    <>
      <Box />
    </>
  );
};

export default CertificatesTab;
