import React from 'react';
import { Box } from '@mui/material';
import styles from './ProductAndServicesPage.module.scss';
import { Loader } from 'tt-ui-lib/core';
import { ProductCard } from '../ProductsPage/ProductCard';
import Pagination from '@mui/material/Pagination/Pagination';
import PaginationItem from '@mui/material/PaginationItem';
import RegistryTable from './RegistryTable';
import { ProductCardListMode } from '../ProductsPage/ProductCardListMode';

const CreditTab = ({
  user,
  activeMode,
  isLoading,
  productsList,
  openProduct,
  pageCount,
  activeChildTab,
  currentPage,
  setCurrentPage,
  pageType,
  editProduct,
  handleLoginClick,
}) => {
  return (
    <>
      {isLoading ? (
        <Box className={styles.noDataBlock}>
          <Loader />
        </Box>
      ) : (
        <>
          {activeChildTab === 0 ? (
            <>
              {productsList.length === 0 && !isLoading ? (
                <Box className={styles.noDataBlock}>No items found...</Box>
              ) : (
                <>
                  {activeMode === 'gridMode' ? (
                    <Box className={styles.productList}>
                      {productsList.map((item) => (
                        <ProductCard
                          key={item.id}
                          existData={item}
                          pressCard={() => openProduct(item.id, item.user_id)}
                          editProduct={(e) => editProduct(e, item.id, item.company_id)}
                          isSeller={!user.id || item.user_id === user?.id}
                          isCredit
                          handleLoginClick={handleLoginClick}
                          user={user}
                          productType="carbon"
                          middlePressCard={() =>
                            window.open(`/dashboard/credit/${item.id}`, '_blank')
                          }
                        />
                      ))}
                    </Box>
                  ) : (
                    <Box className={styles.listMode}>
                      {productsList.map((item) => (
                        <ProductCardListMode
                          key={item.id}
                          existData={item}
                          pressCard={() => openProduct(item.id, item)}
                          middlePressCard={() =>
                            window.open(`/dashboard/credit/${item.id}`, '_blank')
                          }
                          editProduct={(e) => editProduct(e, item.id, item.company_id)}
                          handleLoginClick={handleLoginClick}
                          user={user}
                          productType="carbon"
                          isSeller={!user.id || item.user_id === user?.id}
                          isCredit
                        />
                      ))}
                    </Box>
                  )}
                </>
              )}
              {pageCount > 1 ? (
                <div className={styles.paginationBlock}>
                  <Pagination
                    color="primary"
                    variant="outlined"
                    shape="rounded"
                    page={currentPage}
                    count={pageCount}
                    className={styles.pagination}
                    renderItem={(props) => (
                      <PaginationItem
                        className={[
                          styles.paginationItem,
                          currentPage === props.page ? styles.activePaginationItem : '',
                        ]}
                        {...props}
                        disableRipple
                      />
                    )}
                    onChange={(event, value) => {
                      localStorage.setItem('currentPage', JSON.stringify(value));
                      setCurrentPage(value);
                    }}
                  />
                  <div className={styles.paginationText}>
                    Page {currentPage} of {pageCount}
                  </div>
                </div>
              ) : null}
            </>
          ) : (
            <Box>
              <RegistryTable />
            </Box>
          )}
        </>
      )}
    </>
  );
};

export default CreditTab;
