import React, { useState } from 'react';
import { ButtonBase, Avatar, Box } from '@mui/material';
import Image from '../Image';
import styles from './avatarUploader.module.scss';

const AvatarUploader = ({ avatarSrc, uploadFile, isCreditPage }) => {
  const [avatar, setAvatar] = useState(null);

  const handleUpdateAvatar = async (e) => {
    const avatarFile = e.target.files[0];
    Object.assign(avatarFile, {
      preview: URL.createObjectURL(avatarFile),
    });
    setAvatar(avatarFile);
    await uploadFile(avatarFile);
  };

  return (
    <>
      <div className={styles.avaDiv}>
        {avatar?.preview || avatarSrc ? (
          <Avatar src={avatar?.preview || avatarSrc} sx={{ width: 128, height: 128 }} />
        ) : null}
        {isCreditPage && !avatarSrc ? (
          <Box className={styles.avaButton}>
            <Image src="/images/camera.svg" width={31.2} height={27.6} layout="fixed" />
          </Box>
        ) : !isCreditPage ? (
          <ButtonBase className={styles.avaButton} variant="contained" component="label">
            <Image src="/images/camera.svg" width={31.2} height={27.6} layout="fixed" />
            <input onChange={handleUpdateAvatar} type="file" hidden accept=".png, .jpg, .jpeg" />
          </ButtonBase>
        ) : null}
      </div>
    </>
  );
};

export default AvatarUploader;
