import React, { useEffect, useState } from 'react';
import { Modal, openNotification } from 'tt-ui-lib/core';
import { ReactComponent as CheckSvg } from '../../assets/svg/check.svg';
import { ReactComponent as CopySvg } from '../../assets/svg/copy.svg';
import { ReactComponent as InstagramSvg } from '../../assets/svg/social-media/instagram.svg';
import { ReactComponent as TwitterSvg } from '../../assets/svg/social-media/twitter.svg';
import { ReactComponent as LinkedInSvg } from '../../assets/svg/social-media/linkedIn.svg';
import { ReactComponent as FacebookSvg } from '../../assets/svg/social-media/facebook.svg';
import { ReactComponent as MessengerSvg } from '../../assets/svg/social-media/messenger.svg';
import { ReactComponent as WhatsappSvg } from '../../assets/svg/social-media/whatsapp.svg';
import { ReactComponent as TelegramSvg } from '../../assets/svg/social-media/telegram.svg';
import { ReactComponent as ViberSvg } from '../../assets/svg/social-media/viber.svg';
import {
  FacebookMessengerShareButton,
  FacebookShareButton,
  InstapaperShareButton,
  LinkedinShareButton,
  TelegramShareButton,
  TwitterShareButton,
  ViberShareButton,
  WhatsappShareButton,
} from 'react-share';

import styles from './ShareModal.module.scss';

export const ShareModalWrapper = ({ open, closeModal, link = '' }) => {
  const [copySuccess, setCopySuccess] = useState(false);
  const [copyText, setCopyText] = useState('');

  useEffect(() => {
    if (link) {
      setCopyText(link);
    } else {
      setCopyText(window.location.href);
    }
  }, []);

  useEffect(() => {
    if (copySuccess) {
      setTimeout(() => {
        setCopySuccess(false);
      }, 2000);
    }
  }, [copySuccess]);

  const onCopy = () => {
    navigator.clipboard.writeText(copyText).then(() => {
      openNotification({
        type: 'success',
        message: 'Link copied in clipboard',
      });
      setCopySuccess(true);
    });
  };

  return (
    <Modal
      open={open}
      onClose={() => closeModal()}
      onClick={(e) => e.stopPropagation()}
      title="Share"
      aria-labelledby="parent-modal-title"
      aria-describedby="parent-modal-description"
    >
      <div>
        <div className={styles.shareModalContent}>
          <div className={styles.copyBlock}>
            <div className={styles.copyInput}>{copyText}</div>

            {copySuccess ? (
              <div className={styles.copyBlockButton}>
                <CheckSvg />
                <div className={`${styles.copyBlockButtonGreenText} ${styles.copyBlockButtonText}`}>
                  Copied!
                </div>
              </div>
            ) : (
              <div className={styles.copyBlockButton} onClick={() => onCopy()}>
                <CopySvg />
                <div className={styles.copyBlockButtonText}>Copy link</div>
              </div>
            )}
          </div>

          <div className={styles.socialMediaList}>
            <InstapaperShareButton url={copyText} quote="Instagram">
              <div className={styles.socialMedia}>
                <InstagramSvg />
                <div className={styles.copyBlockButtonText}>Instagram</div>
              </div>
            </InstapaperShareButton>
            <TwitterShareButton url={copyText} quote="Twitter">
              <div className={styles.socialMedia}>
                <TwitterSvg />
                <div className={styles.copyBlockButtonText}>Twitter</div>
              </div>
            </TwitterShareButton>
            <LinkedinShareButton url={copyText} quote="Linkedin">
              <div className={styles.socialMedia}>
                <LinkedInSvg />
                <div className={styles.copyBlockButtonText}>Linkedin</div>
              </div>
            </LinkedinShareButton>
            <FacebookShareButton url={copyText} quote="Facebook">
              <div className={styles.socialMedia}>
                <FacebookSvg />
                <div className={styles.copyBlockButtonText}>Facebook</div>
              </div>
            </FacebookShareButton>
            <FacebookMessengerShareButton url={copyText} quote="Facebook Messenger">
              <div className={styles.socialMedia}>
                <MessengerSvg />
                <div className={styles.copyBlockButtonText}>Facebook Messenger</div>
              </div>
            </FacebookMessengerShareButton>
            <WhatsappShareButton url={copyText} quote="Whatsapp">
              <div className={styles.socialMedia}>
                <WhatsappSvg />
                <div className={styles.copyBlockButtonText}>Whatsapp</div>
              </div>
            </WhatsappShareButton>
            <TelegramShareButton url={copyText} quote="Telegram">
              <div className={styles.socialMedia}>
                <TelegramSvg />
                <div className={styles.copyBlockButtonText}>Telegram</div>
              </div>
            </TelegramShareButton>
            <ViberShareButton url={copyText} quote="Viber">
              <div className={styles.socialMedia}>
                <ViberSvg />
                <div className={styles.copyBlockButtonText}>Viber</div>
              </div>
            </ViberShareButton>
          </div>
        </div>
      </div>
    </Modal>
  );
};
