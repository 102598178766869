import React, { useContext, useEffect, useState } from 'react';
import styles from './companiesListPage.module.scss';
import { Alert, Box, CircularProgress, Grid, InputBase, Snackbar, Typography } from '@mui/material';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import AddIcon from '@mui/icons-material/Add';
import { useLazyQuery } from '@apollo/client';
import { GET_COMPANIES_WITH_PAGINATION } from '../../../api';
import { AppAvatar } from '../../avatar';
import TabSelect from '../../../components/tabs';
import Image from '../../Image';
import Pagination from '@mui/material/Pagination/Pagination';
import PaginationItem from '@mui/material/PaginationItem';
import userContext from '../../../context/User/userContext';
import { UpgradeRoleModal } from '../../../modules/tt-modals';
import KybModal from '../../Modals/KybModal';
import { userCanHaveCompany } from '../../../utils/userConfig';
import { getAvatarPathBySize } from '../../../utils/appUtils';
import ConnectStripeModal from '../../Modals/ConnectStripeModal';
import { Button } from 'tt-ui-kit';
import StripeIcon from '../../icons/StripeIcon';

const TAB_OPTIONS = [
  {
    name: 'My company',
    key: 'my_company',
    child: [
      {
        name: 'Active',
        key: 'active',
        child: [],
      },
      {
        name: 'Draft',
        key: 'draft',
        child: [],
      },
    ],
  },
  {
    name: 'External companies',
    key: 'external_companies',
    child: [
      {
        name: 'Active',
        key: 'active',
        child: [],
      },
      {
        name: 'Draft',
        key: 'draft',
        child: [],
      },
    ],
  },
];

const CompaniesListPage = () => {
  const navigate = useNavigate();
  const { user, setSelectedCompany, selectedCompany } = useContext(userContext);
  const [searchParams, setSearchParams] = useSearchParams();

  const [companiesList, setCompaniesList] = useState(null);
  const [activeTab, setActiveTab] = useState(0);
  const [activeChildTab, setActiveChildTab] = useState(0);
  const [page, setPage] = useState(1);
  const [searchValue, setSearchValue] = useState('');
  const [paginatorInfo, setPaginatorInfo] = useState({});
  const [companyInputData, setCompanyInputData] = useState(null);
  const [isUpgradeRoleModalOpen, setIsUpgradeRoleModalOpen] = useState(false);
  const [openSuccessAlert, setOpenSuccessAlert] = useState(false);
  const [alertText, setAlertText] = useState(false);
  const [kybModalOpen, setKybModalOpen] = useState(false);
  const [kybId, setKybId] = useState(false);
  const [loading, setLoading] = useState(false);
  const [openStripeConnectionModal, setOpenStripeConnectionModal] = useState(false);
  const basePage = '/dashboard/companiesList?activeTab=my_company&activeChildTab=active';
  const [getUserCompanies, { data: companiesData }] = useLazyQuery(GET_COMPANIES_WITH_PAGINATION);

  const isUserExpert = user.role_name === 'expert';

  const joinMarket = (id) => {
    setOpenStripeConnectionModal(id);
  };

  const onChangeCompany = (companyInfo) => {
    setSelectedCompany(companyInfo);
  };

  useEffect(() => {
    console.log('selectedCompany', selectedCompany);
  }, [selectedCompany]);

  const changeCompaniesChildTab = (val) => {
    setLoading(true);
    setCompaniesList(null);
    setPaginatorInfo({});
    setSearchParams({
      activeTab: TAB_OPTIONS[activeTab].key,
      activeChildTab: TAB_OPTIONS[activeTab].child[val].key,
    });
    setActiveChildTab(val);
    setPage(1);
  };

  const changeCompaniesTab = (val) => {
    if (!isUserExpert && val !== 'My company') {
      setIsUpgradeRoleModalOpen(true);
    } else {
      setSearchParams({
        activeTab: TAB_OPTIONS[val].key,
        activeChildTab: TAB_OPTIONS[val].child[0].key,
      });
      setActiveTab(val);
    }
  };

  useEffect(() => {
    const actionStatus = searchParams.get('action');
    if (actionStatus === 'deleted') {
      setAlertText(`Company ${actionStatus} successfully`);
      setOpenSuccessAlert(true);
      searchParams.delete('action');
      setSearchParams(searchParams);
    }
    const activeTabSearchParams = searchParams.get('activeTab');
    const activeChildTabSearchParams = searchParams.get('activeChildTab');
    if (activeTabSearchParams) {
      const activeTabIndex = TAB_OPTIONS.findIndex((item) => item.key === activeTabSearchParams);
      if (activeTabIndex !== -1) {
        setActiveTab(activeTabIndex);
        if (activeChildTabSearchParams) {
          const activeChildTabIndex = TAB_OPTIONS[activeTabIndex].child.findIndex(
            (item) => item.key === activeChildTabSearchParams
          );
          if (activeChildTabIndex !== -1) {
            setActiveChildTab(activeChildTabIndex);
          }
        }
      }
    } else {
      setSearchParams({
        activeTab: TAB_OPTIONS[activeTab].key,
        activeChildTab: TAB_OPTIONS[activeTab].child[activeChildTab].key,
      });
    }
  }, []);

  useEffect(() => {
    if (user.role_name) {
      if (!userCanHaveCompany(user.role_name)) {
        navigate(`/dashboard/companyProfile`);
      }
    }
  }, [user]);

  useEffect(() => {
    if (user?.id) {
      const data = {
        input: {
          user_id: user?.id,
          type: activeTab === 0 ? 'company' : 'external_assessment',
          is_draft: activeChildTab === 1,
          ordersBy: [
            {
              column: 'created_at',
              direction: 'DESC',
            },
          ],
        },
        first: 12,
        page: page,
      };
      if (searchValue && searchValue.length > 1) {
        data.input.partial_name = searchValue;
      }
      setCompanyInputData(data);
    }
  }, [user, activeTab, page, activeChildTab, searchValue]);

  useEffect(() => {
    if (companyInputData) {
      getUserCompanies({
        variables: companyInputData,
        fetchPolicy: 'network-only',
      }).then((res) => {
        if (res.data.getCompaniesByWithPagination.data.length === 0) {
          navigate(`/dashboard/companyProfile`);
        }
      });
    }
  }, [companyInputData, getUserCompanies]);

  useEffect(() => {
    if (companiesData) {
      setCompaniesList(
        companiesData?.getCompaniesByWithPagination?.data
          ? [...companiesData?.getCompaniesByWithPagination?.data]
          : []
      );
      setPaginatorInfo(companiesData?.getCompaniesByWithPagination?.paginatorInfo);
      setLoading(false);
    }
  }, [companiesData]);

  const passKyb = (id) => {
    setKybId(id);
    setKybModalOpen(true);
  };

  const openCompanyPage = (company) => {
    const activeTabSearchParams = searchParams.get('activeTab');
    const activeChildTabSearchParams = searchParams.get('activeChildTab');
    navigate(
      `/dashboard/company/${company.id}/edit?fromActiveTab=${activeTabSearchParams}&fromActiveChildTab=${activeChildTabSearchParams}`
    );
  };

  const openStripeDashboard = (link) => {
    window.open(`${link}`, '_blank');
  };

  const openConnectedStripeDashboard = (pageName, id) => {
    if (typeof window !== 'undefined' && id && basePage) {
      let url = `${process.env.REACT_APP_MARKET}/${pageName}?basePage=${basePage}&id=${id}`;
      window.open(`${url}`, '_blank');
    }
  };

  return (
    <Box className={styles.mainContent}>
      <TabSelect
        setActiveTab={changeCompaniesTab}
        activeTab={activeTab}
        setActiveChildTab={changeCompaniesChildTab}
        activeChildTab={activeChildTab}
        options={TAB_OPTIONS}
      />
      <Box className={styles.filterBlock}>
        <Box className={styles.searchFilter}>
          <Image src="/images/market-place/search-icon.svg" width={19.54} height={19.54} />
          <InputBase
            sx={{ ml: 1, flex: 1 }}
            placeholder="Search"
            onChange={(e) => {
              setSearchValue(e.target.value);
            }}
          />
        </Box>
        <Button
          className={styles.addBtn}
          type="primary"
          startIcon={<AddIcon />}
          component={Link}
          to="/dashboard/companyProfile"
        >
          Create Your Company Account
        </Button>
      </Box>
      <Grid container spacing={2} className={styles.list}>
        {companiesList && !loading ? (
          companiesList.length > 0 &&
          companiesList.map((item, index) => (
            <>
              <Grid
                item
                xs={8}
                className={styles.listItem}
                key={index}
                onClick={() => openCompanyPage(item)}
              >
                <Box className={styles.listItemNameContent}>
                  <AppAvatar
                    nav={1}
                    userName={`${item.company_name}`}
                    src={getAvatarPathBySize(item.logos, 'mediumPath')}
                  />
                  <Typography variant="p" component="p" className={styles.listItemName}>
                    {item.company_name}
                  </Typography>
                </Box>
                {/*{!item.kyb_completed && (*/}
                {/*  <Button*/}
                {/*    onClick={(e) => {*/}
                {/*      e.stopPropagation();*/}
                {/*      passKyb(item.id);*/}
                {/*    }}*/}
                {/*    variant="outlined"*/}
                {/*    className={styles.listItemBtn}*/}
                {/*  >*/}
                {/*    Pass KYB*/}
                {/*  </Button>*/}
                {/*)}*/}
                <Box className={styles.companyActions}>
                  {(item?.paymentAccountList.some(
                    (account) => !account.completed_onboarding && !account.verification_status
                  ) ||
                    !item?.paymentAccountList.length) && (
                    <Button
                      onClick={(e) => {
                        e.stopPropagation();
                        joinMarket(item.id);
                      }}
                      type="primary"
                      className={styles.joinBtn}
                    >
                      Join Market
                    </Button>
                  )}
                  {item?.paymentAccountList.some(
                    (account) => account.verification_status === 'awaiting_document'
                  ) && (
                    <button
                      onClick={(e) => {
                        e.stopPropagation();
                        openStripeDashboard(
                          item?.paymentAccountList.find(
                            (account) => account.verification_status === 'awaiting_document'
                          ).accountLink
                        );
                      }}
                      className={styles.connectStripeButton}
                      type="button"
                    >
                      <StripeIcon />
                      <span>CONTINUE CONNECTING STRIPE</span>
                    </button>
                  )}
                  {item?.paymentAccountList.some(
                    (account) => account.completed_onboarding && !account.verification_status
                  ) && (
                    <button
                      onClick={(e) => {
                        e.stopPropagation();
                        openConnectedStripeDashboard(`payment/loginAccount`, item.id);
                      }}
                      className={styles.connectedStripeButton}
                      type="button"
                    >
                      <StripeIcon />
                      <span>STRIPE CONNECTED</span>
                    </button>
                  )}

                  {selectedCompany?.selectedCompany?.id !== item.id && (
                    <Button
                      type="mainButton"
                      onClick={(e) => {
                        e.stopPropagation();
                        onChangeCompany(item);
                      }}
                      className={styles.joinBtn}
                    >
                      Switch Account
                    </Button>
                  )}
                </Box>
              </Grid>
              <ConnectStripeModal
                key={item.id}
                open={openStripeConnectionModal}
                onClose={() => setOpenStripeConnectionModal(false)}
                companyId={openStripeConnectionModal}
              />
            </>
          ))
        ) : (
          <Box className={styles.loadingBox}>
            <CircularProgress />
          </Box>
        )}
      </Grid>
      {paginatorInfo && paginatorInfo.lastPage > 1 && (
        <div className={styles.paginationBlock}>
          <Pagination
            color="primary"
            variant="outlined"
            shape="rounded"
            page={paginatorInfo.currentPage}
            count={paginatorInfo.lastPage}
            className={styles.pagination}
            renderItem={(props) => (
              <PaginationItem
                className={`${styles.paginationItem} ${
                  paginatorInfo.currentPage === props.page ? styles.activePaginationItem : ''
                }`}
                {...props}
                disableRipple
              />
            )}
            onChange={(event, value) => setPage(value)}
          />
        </div>
      )}
      <UpgradeRoleModal
        open={isUpgradeRoleModalOpen}
        setOpen={setIsUpgradeRoleModalOpen}
        onLinkClick={() =>
          window.location.replace(`${process.env.REACT_APP_TT}/dashboard/change-role`)
        }
      />
      <Snackbar
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        open={openSuccessAlert}
        autoHideDuration={2000}
        onClose={() => setOpenSuccessAlert(false)}
      >
        <Alert
          onClose={() => setOpenSuccessAlert(false)}
          severity="success"
          sx={{ width: '100%', backgroundColor: '#118c12', color: '#ffffff' }}
        >
          {alertText}
        </Alert>
      </Snackbar>

      <KybModal open={kybModalOpen} setOpen={setKybModalOpen} companyId={kybId} mode="own" />
    </Box>
  );
};

export default CompaniesListPage;
